<template>
  <div class="vg_wrapper">
    <div class="vd_search_group">
      <el-form :inline="true" size="min" :model="searchForm" ref="searchForm" @keyup.enter.native="getSmplsNow()">
        <el-row>
          <el-col :md="8">
            <el-form-item label="素材类型:">
              <el-select v-if="numflag === 1" v-model="searchForm.qtso_type" placeholder="请选择素材类型" clearable size="small">
                <el-option v-for="item in smplType" :key="item.id + numflag" :label="item.label" :value="item.id"> </el-option>
              </el-select>
              <el-select v-if="numflag === 2" v-model="searchForm.qtso_type" placeholder="请选择素材类型" clearable size="small">
                <el-option v-for="item in smplType1" :key="item.id + numflag" :label="item.label" :value="item.id"> </el-option>
              </el-select>
              <el-select v-if="numflag === 3" v-model="searchForm.qtso_type" placeholder="请选择素材类型" clearable size="small">
                <el-option v-for="item in smplType2" :key="item.id + numflag" :label="item.label" :value="item.id"> </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :md="8">
            <el-form-item label="素材名称:">
              <el-input size="small" v-model.trim="searchForm.qtso_name" clearable placeholder="请填写素材名称"></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="8">
            <el-form-item label="单据状态:" prop="status">
              <el-select clearable size="small" v-model="searchForm.status" placeholder="请选择单据状态">
                <el-option v-for="item in statusList" :key="item.id" :label="item.label" :value="item.id"> </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <el-row class="vd_mar10">
      <el-col :md="16">
        <el-button size="mini" type="danger" @click="cancel">取消选择</el-button>
        <el-button class="vg_mr_8" size="mini" type="primary" @click="confirmIn()">确认选择</el-button>
      </el-col>
      <el-col :md="8" class="disRight">
        <el-button size="small" type="primary" icon="el-icon-search" @click="getSmplsNow()" class="vg_ml_16">查询 </el-button>
        <el-button size="small" type="info" icon="el-icon-refresh" @click="getSmplsNowO()" class="vg_ml_16">刷新 </el-button>
      </el-col>
    </el-row>
    <el-row class="vd_mrt">
      <el-col :md="24">
        <el-table ref="multiTable" v-loading="loading" :data="tableData" :row-key="getRowKey" border @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="48" align="center" :reserve-selection="true" />
          <el-table-column label="素材类型" prop="qtso_type" :formatter="formatterQtsoType" />
          <el-table-column label="素材名称" prop="qtso_name" />
          <el-table-column label="素材规格" prop="qtso_spec">
            <template slot-scope="scope">
              <span v-if="scope.row.qtso_spec">{{ scope.row.qtso_spec }}</span>
              <span v-else class="vg_9f9a9a">暂无</span>
            </template>
          </el-table-column>
          <el-table-column label="素材价格" align="right" prop="qtso_price" :formatter="qtsoPrice" />
          <el-table-column label="是否是日价" prop="qtso_daypr" :formatter="qtsoDaypr" />
          <el-table-column label="单据状态" :show-overflow-tooltip="true" align="center">
            <template slot-scope="scope">
              <el-tag :type="helper.getStatusName(scope.row.status).type" size="mini"> {{ helper.getStatusName(scope.row.status).name }}</el-tag>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <el-row>
      <el-col :md="24">
        <pubPagination ref="pubPagination" :totalPage="totalPage" @changePageSearch="changePageSearch"></pubPagination>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { get } from '@api/request';
import { QtsoAPI } from '@api/modules/qtso';
import pubPagination from '@/components/common/pubPagination';
import helper from '@assets/js/helper.js';
// 二部报价明细三个子表的弹窗
export default {
  name: 'SmplEditBom',
  components: {
    pubPagination
  },
  props: {
    numflag: {
      type: Number,
      required: true
    }
  },
  watch: {
    numflag(newValue, oldValue) {
      console.log(this.searchForm.qtso_type);
      console.log(newValue);
    }
  },
  data() {
    return {
      tableData: [],
      searchForm: {
        qtso_type: null,
        qtso_name: null,
        status: null
      },
      totalPage: 0,
      btn: {},
      loading: true,
      multiSelection: [],
      currentPage: 1,
      smplType: [
        { id: 1, label: '原材料' },
        { id: 2, label: '香精' },
        { id: 3, label: '夜光粉' },
        { id: 4, label: '颗粒' },
        { id: 5, label: '矿粉' },
        { id: 6, label: '色粉' }
      ],
      smplType1: [
        { id: 7, label: '成品损耗' },
        { id: 8, label: '辅助料材' },
        { id: 9, label: '水电费' },
        { id: 10, label: '运费' },
        { id: 11, label: '纸箱' },
        { id: 12, label: '塑料袋' }
      ],
      smplType2: [{ id: 13, label: '人工工资' }],
      qtsoTypeList: [
        { value: '1', label: '原材料' },
        { value: '2', label: '香精' },
        { value: '3', label: '夜光粉' },
        { value: '4', label: '颗粒' },
        { value: '5', label: '矿粉' },
        { value: '6', label: '色粉' },
        { value: '7', label: '成品损耗' },
        { value: '8', label: '辅助料材' },
        { value: '9', label: '水电费' },
        { value: '10', label: '运费' },
        { value: '11', label: '纸箱' },
        { value: '12', label: '塑料袋' },
        { value: '13', label: '人工工资' }
      ],
      statusList: [
        {
          id: 0,
          label: '草拟'
        },
        {
          id: 1,
          label: '在批'
        },
        {
          id: 2,
          label: '生效'
        }
      ]
    };
  },
  created() {
    // this.initData()
  },
  filters: {
    foamatSmplPrice(row) {
      return helper.haveFour(row);
    },
    formatSmplVerifyPrice(row) {
      return helper.haveFour(row);
    },
    formatSmplNum(row) {
      return helper.haveFour(row);
    }
  },
  methods: {
    initData() {
      this.loading = true;
      this.getSmplsList();
    },
    // 获取物料信息
    getSmplsList() {
      get(QtsoAPI.getAllQtsosV1, {
        qtso_type: this.searchForm.qtso_type,
        qtso_name: this.searchForm.qtso_name,
        status: this.searchForm.status,
        type_flag: this.numflag,
        page_no: this.currentPage
      })
        .then(res => {
          if (res.data.code === 0) {
            this.tableData = res.data.data.list;
            this.totalPage = res.data.data.total;
            this.btn = res.data.data.btn;
            this.loading = false;
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 清空
    clear() {
      this.$refs.multiTable.clearSelection();
    },
    // 查询方法
    getSmplsNow() {
      this.$refs.pubPagination.currentPage = 1;
      this.currentPage = 1;
      this.$refs.multiTable.clearSelection();
      this.getSmplsList();
    },
    // 刷新
    getSmplsNowO() {
      this.$refs.pubPagination.currentPage = 1;
      this.currentPage = 1;
      this.searchForm = {
        smpl_name: '',
        smpl_no: '',
        smpl_type: '',
        status: null
      };
      this.$refs.multiTable.clearSelection();
      this.getSmplsList();
    },
    // 指定一个key标识这一行的数据
    getRowKey(row) {
      return row.smpl_id;
    },
    // //新增
    goSmpl() {
      this.$emit('falseSmpl');
      this.jump(`/smpl_list?perm_id=${this.helper.retPermId('smpl')}`);
    },
    // 多选
    handleSelectionChange(val) {
      this.multiSelection = [];
      this.multiSelection = val;
    },
    // 分页查询
    changePageSearch(val) {
      this.currentPage = val;
      this.getSmplsList();
    },
    // 时间转换
    formatDate1(row) {
      return this.helper.toStringDate(row.smpl_deil_time);
    },
    // 是否为日价转换
    qtsoDaypr(row) {
      if (row.qtso_daypr === 1) {
        return '是';
      }
      return '否';
    },
    // 价格转换
    qtsoPrice(val) {
      return this.helper.haveFour(val.qtso_price);
    },
    // 样品类型转换
    formatSmplType(row) {
      if (row.smpl_type === 1) {
        return '新款';
      } else if (row.smpl_type === 2) {
        return '老款';
      } else if (row.smpl_type === 3) {
        return '修改款';
      }
    },
    // 取消选择
    cancel() {
      this.clear();
      this.$emit('childQtsoChanel');
    },
    // 确认选择
    confirmIn() {
      if (this.multiSelection.length > 0) {
        this.$emit('childQtso', this.multiSelection);
        this.cancel();
      } else {
        this.$message({
          type: 'warning',
          message: '至少选中一条数据'
        });
      }
    },
    formatterQtsoType(row) {
      let str = '';
      this.qtsoTypeList.forEach(item => {
        if (row.qtso_type == item.value) {
          str = item.label;
        }
      });
      return str;
    }
  }
};
</script>

<style scoped lang="scss">
.vd_elimg {
  width: 60px;
  height: 60px;
}

.vd_mrt {
  margin-top: 20px;
}

.vd_mar10 {
  margin: 10px 0;
}
</style>
