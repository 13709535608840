var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('el-table',{attrs:{"data":_vm.qutbForm.qutb_supp_list,"border":""},on:{"selection-change":_vm.handleSelectionChange}},[_c('el-table-column',{attrs:{"type":"selection","width":"48","align":"center"}}),_c('el-table-column',{attrs:{"label":"序号","width":"48","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(scope.$index + 1)+" ")]}}])}),_c('el-table-column',{attrs:{"label":"供应商简称"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-form-item',{attrs:{"label-width":"0","prop":'qutb_supp_list.' + scope.$index + '.supp_abbr'}},[_c('el-input',{attrs:{"disabled":"","maxlength":"30","show-word-limit":"","placeholder":"暂无供应商简称"},model:{value:(_vm.qutbForm.qutb_supp_list[scope.$index].supp_abbr),callback:function ($$v) {_vm.$set(_vm.qutbForm.qutb_supp_list[scope.$index], "supp_abbr", $$v)},expression:"qutbForm.qutb_supp_list[scope.$index].supp_abbr"}})],1)]}}])}),_c('el-table-column',{scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-form-item',{attrs:{"label-width":"0","prop":'qutb_supp_list.' + scope.$index + '.qutb_supp_total',"rules":[{ required: true }]}},[_c('el-input',{attrs:{"maxlength":"9","show-word-limit":"","placeholder":"暂无含税报价"},on:{"input":function($event){_vm.qutbForm.qutb_supp_list[scope.$index].qutb_supp_total = _vm.helper.keepTNum2(
                _vm.qutbForm.qutb_supp_list[scope.$index].qutb_supp_total
              )},"change":function($event){_vm.qutbForm.qutb_supp_list[scope.$index].qutb_supp_total = _vm.helper.calcPrice(
                _vm.qutbForm.qutb_supp_list[scope.$index].qutb_supp_total,
                4,
                10000
              )}},model:{value:(_vm.qutbForm.qutb_supp_list[scope.$index].qutb_supp_total),callback:function ($$v) {_vm.$set(_vm.qutbForm.qutb_supp_list[scope.$index], "qutb_supp_total", $$v)},expression:"qutbForm.qutb_supp_list[scope.$index].qutb_supp_total"}})],1)]}}])},[_c('template',{slot:"header"},[_c('span',{staticClass:"vg_deep_red"},[_vm._v("含税报价")])])],2),_c('el-table-column',{scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-form-item',{attrs:{"label-width":"0","prop":'qutb_supp_list.' + scope.$index + '.qutb_supp_mcost',"rules":[{ required: true }]}},[_c('el-input',{attrs:{"maxlength":"9","show-word-limit":"","placeholder":"暂无模具费"},on:{"input":function($event){_vm.qutbForm.qutb_supp_list[scope.$index].qutb_supp_mcost = _vm.helper.keepTNum2(
                _vm.qutbForm.qutb_supp_list[scope.$index].qutb_supp_mcost
              )},"change":function($event){_vm.qutbForm.qutb_supp_list[scope.$index].qutb_supp_mcost = _vm.helper.calcPriceT(
                _vm.qutbForm.qutb_supp_list[scope.$index].qutb_supp_mcost,
                2,
                1000000
              )}},model:{value:(_vm.qutbForm.qutb_supp_list[scope.$index].qutb_supp_mcost),callback:function ($$v) {_vm.$set(_vm.qutbForm.qutb_supp_list[scope.$index], "qutb_supp_mcost", $$v)},expression:"qutbForm.qutb_supp_list[scope.$index].qutb_supp_mcost"}})],1)]}}])},[_c('template',{slot:"header"},[_c('span',{staticClass:"vg_deep_red"},[_vm._v("模具费")])])],2),_c('el-table-column',{scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-form-item',{attrs:{"label-width":"0","prop":'qutb_supp_list.' + scope.$index + '.qutb_supp_mpc',"rules":[{ required: true }]}},[_c('el-input',{attrs:{"maxlength":"10","show-word-limit":"","placeholder":"暂无模具付数"},on:{"input":function($event){_vm.qutbForm.qutb_supp_list[scope.$index].qutb_supp_mpc = _vm.helper.pureNumber(_vm.qutbForm.qutb_supp_list[scope.$index].qutb_supp_mpc)}},model:{value:(_vm.qutbForm.qutb_supp_list[scope.$index].qutb_supp_mpc),callback:function ($$v) {_vm.$set(_vm.qutbForm.qutb_supp_list[scope.$index], "qutb_supp_mpc", $$v)},expression:"qutbForm.qutb_supp_list[scope.$index].qutb_supp_mpc"}})],1)]}}])},[_c('template',{slot:"header"},[_c('span',{staticClass:"vg_deep_red"},[_vm._v("模具付数")])])],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }