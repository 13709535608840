<template>
  <div class="vg_wrapper">
    <el-form :inline="true" size="min" :model="searchForm" ref="searchForm" @keyup.enter.native="getSuppsNow()" label-width="120px">
      <el-row>
        <el-col :md="8">
          <el-form-item label="供应商简称:" prop="supp_abbr">
            <el-input size="small" v-model.trim="searchForm.supp_abbr" clearable placeholder="请填写供应商名称"></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="12">
          <el-form-item label="供应商类型:" prop="supp_type">
            <el-select v-model="searchForm.supp_type" placeholder="请选择供应商类型" size="small" clearable>
              <el-option v-for="item in suppType" :key="item.id" :label="item.label" :value="item.id"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button size="small" type="primary" icon="el-icon-search" @click="getSuppsNow()" class="vg_ml_16">查询 </el-button>
            <el-button type="info" size="small" class="vd_export" @click="buttonRefresh()"
              ><i class="el-icon-refresh-right"></i> 刷新</el-button
            >
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-row class="vd_mar10">
      <el-button class="vg_mr_8" size="mini" type="primary" @click="confirmIn()">确认选择</el-button>
      <el-button size="mini" type="danger" @click="cancel">取消选择</el-button>
    </el-row>
    <el-row class="vd_mrt">
      <el-col :md="24">
        <el-table
          ref="multiTable"
          :data="tableData"
          @selection-change="handleSelectionChange"
          :row-key="getRowKey"
          border
          v-loading="loading"
        >
          <el-table-column type="selection" width="48" align="center" :reserve-selection="true" />
          <el-table-column label="供应商名称" prop="supp_name" />
          <el-table-column label="供应商类型" prop="supp_type">
            <template slot-scope="scope">
              <div v-if="scope.row.supp_type">
                {{ scope.row.supp_type | foamatSuppType }}
              </div>
              <div v-else class="vg_9f9a9a">暂无</div>
            </template>
          </el-table-column>
          <el-table-column label="供应商简称" prop="supp_abbr"> </el-table-column>
          <el-table-column label="累计订单数" prop="supp_ordr_total" />
          <el-table-column label="创建时间" prop="create_time" :formatter="formatDate1" show-overflow-tooltip />
        </el-table>
      </el-col>
    </el-row>
    <el-row>
      <el-col :md="24">
        <pubPagination ref="pubPagination" :totalPage="totalPage" @changePageSearch="changePageSearch"></pubPagination>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { suppAPI } from '@api/modules/supp';
import pubPagination from '@/components/common/pubPagination';
import helper from '@assets/js/helper.js';

export default {
  name: 'SmplEditBom',
  components: {
    pubPagination
  },
  data() {
    return {
      tableData: [],
      searchForm: {
        supp_abbr: null,
        supp_type: null
      },
      totalPage: 0,
      btn: {},
      loading: true,
      multiSelection: [],
      currentPage: 1,
      suppType: [
        { id: 1, label: '加工厂' },
        { id: 2, label: '面料厂' },
        { id: 3, label: '配套厂' },
        { id: 4, label: '辅料厂' }
      ]
    };
  },
  created() {
    this.initData();
  },
  filters: {
    foamatSuppType(row) {
      console.log('row', row);
      if (row === 1) {
        return '加工厂';
      } else if (row === 2) {
        return '面料厂';
      } else if (row === 3) {
        return '配套厂';
      } else if (row === 4) {
        return '辅料厂';
      }
    }
  },
  methods: {
    initData() {
      this.loading = true;
      this.getSmplsList();
    },
    // 获取物料信息
    getSmplsList() {
      get(suppAPI.getSupps, {
        supp_type: this.searchForm.supp_type,
        page_no: this.currentPage,
        supp_abbr: this.searchForm.supp_abbr
      })
        .then(res => {
          if (res.data.code === 0) {
            this.tableData = res.data.data.list;
            this.totalPage = res.data.data.total;
            this.btn = res.data.data.btn;
            setTimeout(() => {
              this.loading = false;
            }, 500);
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 清空
    clear() {
      this.$refs.multiTable.clearSelection();
    },
    // 查询方法
    getSuppsNow() {
      this.loadFlag = true;
      this.$refs.pubPagination.currentPage = 1;
      this.currentPage = 1;
      this.initData();
    },
    // 刷新
    buttonRefresh() {
      this.searchForm = {
        supp_abbr: null,
        supp_type: null
      };
      this.loadFlag = true;
      this.currentPage = 1;
      this.$refs.pubPagination.currentPage = 1;
      this.initData();
    },
    // 指定一个key标识这一行的数据
    getRowKey(row) {
      return row.supp_id;
    },
    // 多选
    handleSelectionChange(val) {
      this.multiSelection = [];
      this.multiSelection = val;
    },
    // 分页查询
    changePageSearch(val) {
      this.currentPage = val;
      this.initData();
    },
    // 时间转换
    formatDate1(row) {
      return this.helper.toStringDate(row.create_time);
    },
    // 取消选择
    cancel() {
      this.clear();
      this.$emit('childSuppChanel');
    },
    // 确认选择
    confirmIn() {
      if (this.multiSelection.length > 0) {
        this.$emit('childSmpl', this.multiSelection);
        this.clear();
      } else {
        this.$message({
          type: 'warning',
          message: '至少选中一条数据'
        });
      }
    }
  }
};
</script>

<style scoped lang="scss">
.vd_elimg {
  width: 60px;
  height: 60px;
}
.vd_mrt {
  margin-top: 20px;
}
.vd_mar10 {
  margin: 10px 0;
}
</style>
