<template>
  <div class="vg_wrapper" v-loading="loadingFlag">
    <div v-sticky class="vd_edhea vg_button_group" style="padding-top: 16px; background: white">
      <editHeader :btn="btn" :isShow="isShow" :strForm="qutbForm" @closeEdit="closeEdit" @openEdit="openEdit" @submit="submit('qutbForm')" />
      <div v-if="isShow" class="vd_export vg_mt_16">
        <el-button type="primary" size="small" @click="buttonRefresh()">刷新</el-button>
        <!--<el-button size="small" icon="el-icon-printer" type="info" @click="clickPrint" v-if="qutbForm.status === 2">打印 </el-button>-->
        <el-button v-if="qutbForm.status === 2" icon="el-icon-bottom" size="small" type="success" @click="clickExport">导出Excel</el-button>
      </div>
      <el-col :md="4" class="vd_export2 vg_mt_16 flexHV">
        <span style="margin-right: 2px">报价单号:</span>
        <el-input v-model="qutbForm.qutb_no" disabled show-word-limit size="small" style="width: 150px">
          <template slot="append">
            <el-link :disabled="isShow" type="primary" @click="dialogVisible2 = true">选择</el-link>
          </template>
        </el-input>
      </el-col>
    </div>
    <el-form ref="qutbForm" :model="qutbForm" :rules="rules" label-width="120px" size="mini" :disabled="isShow">
      <el-row class="vg_mb_5" style="flex-wrap: wrap" type="flex">
        <el-col :md="16" class="vg_mb_5">
          <el-row class="vg_mb_5">
            <el-col :md="12">
              <el-form-item label="委托打样单号" prop="requ_no">
                <el-input maxlength="30" v-model="qutbForm.requ_no" disabled placeholder="请填写委托打样单号" show-word-limit> </el-input>
              </el-form-item>
            </el-col>
            <el-col :md="12">
              <el-form-item label="工厂打样单号" prop="fequ_no">
                <el-input v-model="qutbForm.fequ_no" disabled maxlength="30" placeholder="请填写工厂打样单号" show-word-limit>
                  <div v-if="qutbForm.order_type === 1" slot="append">
                    <el-link :disabled="isShow" class="vg_cursor" type="primary" @click="dialogVisible2 = true">选择</el-link>
                  </div>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="vg_mb_5">
            <el-col :md="12">
              <el-form-item label="样品名称" prop="prod_name">
                <el-input maxlength="30" v-model="qutbForm.prod_name" disabled placeholder="请填写样品名称" show-word-limit> </el-input>
              </el-form-item>
            </el-col>
            <el-col :md="12">
              <el-form-item label="客户简称" prop="cust_abbr">
                <el-input maxlength="30" v-model="qutbForm.cust_abbr" disabled placeholder="请填写客户简称" show-word-limit> </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="vg_mb_5">
            <el-col :md="12">
              <el-form-item label="我司货号" prop="prod_no">
                <el-input maxlength="30" v-model="qutbForm.prod_no" disabled placeholder="请填写我司货号" show-word-limit> </el-input>
              </el-form-item>
            </el-col>
            <el-col :md="12">
              <el-form-item label="客户货号" prop="cust_no">
                <el-input maxlength="30" v-model="qutbForm.cust_no" disabled placeholder="暂不填写" show-word-limit> </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="vg_mb_5">
            <el-col :md="12">
              <el-form-item label="更改号" prop="prod_suffix">
                <el-input
                  disabled
                  maxlength="2"
                  @input="val => (qutbForm.prod_suffix = keepNumber(val))"
                  @change="qutbForm.prod_suffix !== '' || (qutbForm.prod_suffix = 0)"
                  v-model="qutbForm.prod_suffix"
                  placeholder="请填写更改号"
                  show-word-limit
                />
              </el-form-item>
            </el-col>
            <el-col :md="12">
              <el-form-item label="供应商简称" prop="supp_abbr">
                <el-input maxlength="30" v-model="qutbForm.supp_abbr" disabled placeholder="请填写供应商简称" show-word-limit> </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="vg_mb_5">
            <el-col :md="12">
              <el-form-item label="含税价格" prop="qutb_total">
                <el-input maxlength="30" disabled v-model="qutbForm.qutb_total" placeholder="请填写含税价格" show-word-limit> </el-input>
              </el-form-item>
            </el-col>
            <el-col :md="12">
              <el-form-item label="样品单位" prop="prod_unit">
                <el-input maxlength="30" disabled v-model="qutbForm.prod_unit" placeholder="请选择样品单位" show-word-limit> </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="vg_mb_5">
            <el-col :md="12">
              <el-form-item label="采购部建议价" prop="procu_quota">
                <el-input
                  v-model="qutbForm.procu_quota"
                  disabled
                  maxlength="11"
                  placeholder="请填写"
                  show-word-limit
                  @input="val => (qutbForm.procu_quota = keep4Decimal(val))"
                >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :md="12">
              <el-form-item label="工厂报价" prop="factory_quota">
                <el-input
                  v-model="qutbForm.factory_quota"
                  disabled
                  maxlength="11"
                  placeholder="请填写"
                  show-word-limit
                  @input="val => (qutbForm.factory_quota = keep4Decimal(val))"
                >
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="vg_mb_5">
            <el-col :md="12">
              <el-form-item label="样品材质" prop="qutb_made">
                <el-input v-model="qutbForm.qutb_made" :show-word-limit="true" disabled placeholder="请填写"></el-input>
                <!--                <el-select filterable v-model="qutbForm.qutb_made" placeholder="请选择样品材质">
                  <el-option v-for="item in qutbMadeType" :key="item.id" :label="item.label" :value="item.id"> </el-option>
                </el-select>-->
              </el-form-item>
            </el-col>
            <el-col :md="12">
              <el-form-item label="产品重量" prop="qutb_weight">
                <el-input :value="qutb_weight" disabled placeholder="请填写产品重量" show-word-limit></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="vg_mb_5">
            <el-col :md="12">
              <el-form-item label="规格" prop="qutb_spec">
                <el-input v-model="qutbForm.qutb_spec" :rows="2" maxlength="100" placeholder="请填写规格" show-word-limit type="textarea"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="12">
              <el-form-item label="装箱数" prop="qutb_qpc">
                <el-input
                  maxlength="6"
                  @input="val => (qutbForm.qutb_qpc = keepNumber(val))"
                  v-model="qutbForm.qutb_qpc"
                  placeholder="请填写装箱数"
                  show-word-limit
                >
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-col>
        <el-col :md="8" class="vg_mb_5">
          <el-form-item label="产品主图" prop="imge_id">
            <div class="vd_dis">
              <div>
                <el-upload
                  class="upload-demo"
                  drag
                  :action="uploadUrl"
                  :data="dataBody"
                  :show-file-list="false"
                  :on-success="handleAvatarSuccess"
                  :before-upload="beforeAvatarUpload"
                  ref="uploadMutiple"
                >
                  <!-- <i class="el-icon-upload"></i> -->
                  <imgPopover v-if="qutbForm.imge_id" :imgeUrl="qutbForm.imge_url" :imgeUrlO="qutbForm.imge_urlO"> </imgPopover>
                  <i v-else class="el-icon-upload avatar-uploader-icon vg_pointer" size="40px"></i>
                </el-upload>
                <span>大小不能超过10MB</span>
              </div>
              <div id="preview" @paste="handlePaste" class="vd_flex_right">
                <span><i class="el-icon-s-opportunity" style="color: #fb894c"></i>点击此处并按Ctrl+V粘贴</span>
              </div>
            </div>
          </el-form-item>
        </el-col>
        <el-col :md="8" class="vg_mb_5">
          <el-form-item label="纸箱长度" prop="qutb_box_l">
            <el-input
              maxlength="10"
              @change="calcQutbAccessMoney"
              @input="val => (qutbForm.qutb_box_l = keep2Decimal(val))"
              v-model="qutbForm.qutb_box_l"
              placeholder="请填写纸箱长度"
              show-word-limit
            >
              <template slot="append">cm</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8" class="vg_mb_5">
          <el-form-item label="纸箱宽度" prop="qutb_box_w">
            <el-input
              maxlength="10"
              @change="calcQutbAccessMoney"
              v-model="qutbForm.qutb_box_w"
              @input="val => (qutbForm.qutb_box_w = keep2Decimal(val))"
              placeholder="请填写纸箱宽度"
              show-word-limit
            >
              <template slot="append">cm</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8" class="vg_mb_5">
          <el-form-item label="纸箱高度" prop="qutb_box_h">
            <el-input
              maxlength="10"
              @change="calcQutbAccessMoney"
              v-model="qutbForm.qutb_box_h"
              @input="val => (qutbForm.qutb_box_h = keep2Decimal(val))"
              placeholder="请填写纸箱高度"
              show-word-limit
            >
              <template slot="append">cm</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8" class="vg_mb_5">
          <el-form-item label="塑料袋长度" prop="qutb_bag_l">
            <el-input
              maxlength="10"
              v-model="qutbForm.qutb_bag_l"
              @input="val => (qutbForm.qutb_bag_l = keep2Decimal(val))"
              placeholder="请填写塑料袋长度"
              show-word-limit
            >
              <template slot="append">cm</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8" class="vg_mb_5">
          <el-form-item label="塑料袋宽度" prop="qutb_bag_w">
            <el-input
              maxlength="10"
              v-model="qutbForm.qutb_bag_w"
              @input="val => (qutbForm.qutb_bag_w = keep2Decimal(val))"
              placeholder="请填写塑料袋宽度"
              show-word-limit
            >
              <template slot="append">cm</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8" class="vg_mb_5">
          <el-form-item label="塑料袋高" prop="qutb_bag_h">
            <el-input
              maxlength="10"
              v-model="qutbForm.qutb_bag_h"
              @input="val => (qutbForm.qutb_bag_h = keep2Decimal(val))"
              placeholder="请填写塑料袋高度"
              show-word-limit
            >
              <template slot="append">cm</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8" class="vg_mb_5">
          <el-form-item label="装袋数" prop="qutb_qpb">
            <el-input
              maxlength="10"
              @input="val => (qutbForm.qutb_qpb = keepNumber(val))"
              v-model="qutbForm.qutb_qpb"
              placeholder="请填写装袋数"
              show-word-limit
            >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8" class="vg_mb_5">
          <el-form-item label="核价接收人" prop="qutb_pricer">
            <el-select v-model="qutbForm.qutb_pricer" size="small">
              <el-option v-for="item in users2" :key="item.stff_id" :label="item.stff_name" :value="item.stff_id"> </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8" class="vg_mb_5">
          <el-form-item label="核价日期" prop="qutb_pridate">
            <el-date-picker v-model="qutbForm.qutb_pridate" clearable size="small" type="date" placeholder="选择日期"> </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :md="8" class="vg_mb_5">
          <el-form-item label="起订量" prop="qutb_moq">
            <el-input
              maxlength="10"
              @input="val => (qutbForm.qutb_moq = keepNumber(val))"
              v-model="qutbForm.qutb_moq"
              placeholder="请填写起订量"
              show-word-limit
            >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="24" class="vg_mb_5">
          <el-form-item label="备注" prop="qutb_remark">
            <el-input v-model="qutbForm.qutb_remark" :rows="2" maxlength="255" placeholder="请填写备注" show-word-limit type="textarea"></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="24" class="vg_mb_5">
          <el-form-item label="模具备注" prop="mould_remarks">
            <el-input v-model="qutbForm.mould_remarks" :rows="2" maxlength="255" placeholder="请填写备注" show-word-limit type="textarea"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <div class="vd_dash"></div>
      <!--      <el-row>
        <el-col :md="4">
          <QutbMould @moldDayOutput="moldDayOutput" :qutbForm="qutbForm"></QutbMould>
        </el-col>
        <el-col :md="10" :offset="1">
          <div class="vd_but">
            <el-button type="success" plain size="small" @click="importSupp">导入供应商</el-button>
            <el-button type="danger" plain size="small" @click="clickDelSupp">删除</el-button>
          </div>
          <QutbSupp :qutbForm="qutbForm" @handleSelectionChange="handleSelectionChange"></QutbSupp>
        </el-col>
      </el-row>-->
      <el-row>
        <el-tabs :value="'first'">
          <el-tab-pane label="原材料" name="first" :key="'first'">
            <div class="vd_but">
              <el-button type="success" plain size="small" @click="importBom(1)">导入</el-button>
              <el-button type="danger" plain size="small" @click="deleteBom">删除</el-button>
            </div>
            <QutbBom
              v-if="qutbForm.qutb_qtso_mtrl_list"
              ref="QutbBom"
              :isShow="isShow"
              :qutbForm="qutbForm"
              @changeRubberMaterial="$refs.QutbOffer.calcQutbMoney"
              @handleSelectionChangeBom="handleSelectionChangeBom"
            ></QutbBom>
          </el-tab-pane>
        </el-tabs>
        <el-tabs :value="'second'">
          <el-tab-pane label="辅助材料" name="second" :key="'second'">
            <div class="vd_but">
              <el-button type="success" plain size="small" @click="importBom(2)">导入</el-button>
              <el-button type="danger" plain size="small" @click="deleteAccess">删除</el-button>
            </div>
            <QutbAccess
              v-if="qutbForm.qutb_qtso_accmtrl_list"
              ref="QutbAccess"
              :isShow="isShow"
              @setFinishedProductLoss="qutb_qtso_num"
              @handleSelectionChangeAccess="handleSelectionChangeAccess"
              :qutbForm="qutbForm"
            ></QutbAccess>
          </el-tab-pane>
        </el-tabs>
        <el-tabs :value="'third'">
          <el-tab-pane label="人工工资" name="third" :key="'third'">
            <div class="vd_but">
              <el-button type="success" plain size="small" @click="importBom(3)">导入</el-button>
              <el-button type="danger" plain size="small" @click="deleteOffer">删除</el-button>
            </div>
            <QutbOffer
              v-if="qutbForm.qutb_qtso_sala_list"
              ref="QutbOffer"
              :isShow="isShow"
              @handleSelectionChangeOffer="handleSelectionChangeOffer"
              @setFinishedProductLoss="qutb_qtso_num"
              :qutbForm="qutbForm"
            ></QutbOffer>
          </el-tab-pane>
        </el-tabs>
        <el-tabs :value="'four'">
          <el-tab-pane label="管税利" name="four" :key="'four'">
            <QutbAdmin :isShow="isShow" :qutbForm="qutbForm"></QutbAdmin>
          </el-tab-pane>
        </el-tabs>
      </el-row>
      <div class="vd_dash"></div>
      <el-row class="vg_mt_16 vd_bortop">
        <el-col>
          <inputUser :isCookie="false" :stffForm="stffForm" ref="userData" v-if="qutbForm.stff_name"></inputUser>
        </el-col>
      </el-row>
    </el-form>
    <el-dialog :visible.sync="dialogVisible" title="供应商" width="70%">
      <QutbSuppList @childSuppChanel="childSuppChanel" @childSmpl="childSmpl"></QutbSuppList>
    </el-dialog>
    <el-dialog :visible.sync="dialogVisible1" title="素材" width="70%">
      <QutbMacerList :numflag="numflag" @childQtso="childQtso" @childQtsoChanel="childQtsoChanel" ref="QutbMacerList"> </QutbMacerList>
    </el-dialog>
    <el-dialog :visible.sync="dialogVisible2" title="工厂打样明细" width="70%" @opened="getDialogData">
      <DynamicUTable
        ref="dialogTable"
        :columns="dialogTableProperties"
        :dbClickJp="dialogDbClick"
        :need-check-box="false"
        :need-search="true"
        :needPagination="true"
        :table-data="dialogTableData"
        @getTableData="getDialogData"
      ></DynamicUTable>
    </el-dialog>
  </div>
</template>

<script>
import { get, getNoCatch, postNoCatch } from '@api/request';
import { qutbAPI } from '@api/modules/qutb';
import { imgeAPI } from '@api/modules/imge';
import { stffAPI } from '@/api/modules/staff';
import editHeader from '@/views/component/editHeader';
import inputUser from '@/views/component/inputUser';
import helper from '@assets/js/helper';
import imgPopover from '@/views/component/imgPopover';
import QutbSupp from './Componet/QutbSupp.vue';
import QutbMould from './Componet/QutbMould.vue';
import QutbAdmin from './Componet/QutbAdmin.vue';
import QutbBom from './Componet/QutbBom.vue';
import QutbAccess from './Componet/QutbAccess.vue';
import QutbOffer from './Componet/QutbOffer.vue';
import QutbSuppList from './Componet/QutbSuppList.vue';
import QutbMacerList from './Componet/QutbMacerList.vue';
import { keep2Decimal, keep4Decimal, keepNumber } from '@assets/js/regExUtil';
import { objectArrayReduce } from '@assets/js/arrayUtils';
import { requiredReminderAndUGrid } from '@assets/js/domUtils';
import { BigNumber } from 'bignumber.js';
import DynamicUTable from '@/components/table/DynamicUTable.vue';
import { dialogTableProperties } from '@/views/DevelopManagement/QutbManage/qutb';
import { downloadFile } from '@api/public';
import UrlEncode from '@assets/js/UrlEncode';

export default {
  name: 'qutbEditMain',
  components: {
    DynamicUTable,
    editHeader,
    inputUser,
    imgPopover,
    QutbSupp,
    QutbMould,
    QutbAdmin,
    QutbBom,
    QutbAccess,
    QutbOffer,
    QutbSuppList,
    QutbMacerList
  },
  data() {
    return {
      rules: {
        qutb_total: [{ required: true, trigger: 'blur', message: ' ' }],
        qutb_made: [{ required: true, trigger: ['blur', 'change'], message: ' ' }],
        qutb_qpc: [{ required: true, trigger: 'blur', message: ' ' }],
        qutb_box_l: [{ required: true, trigger: 'blur', message: ' ' }],
        qutb_box_w: [{ required: true, trigger: 'blur', message: ' ' }],
        qutb_box_h: [{ required: true, trigger: 'blur', message: ' ' }],
        qutb_bag_l: [{ required: true, trigger: 'blur', message: ' ' }],
        qutb_bag_w: [{ required: false, trigger: 'blur', message: ' ' }],
        qutb_qpb: [{ required: true, trigger: 'blur', message: ' ' }],
        qutb_moq: [{ required: true, trigger: 'blur', message: ' ' }],
        qutb_weight: [{ required: true, trigger: 'blur', message: ' ' }],
        imge_id: [{ required: true, trigger: 'blur', message: ' ' }]
      },
      qutbForm: {
        prod_name: null,
        requ_no: null,
        fequ_no: null,
        cust_abbr: null,
        prod_no: null,
        cust_no: null,
        qutb_total: null,
        prod_unit: null,
        qutb_made: null,
        qutb_weight: null,
        qutb_box_l: null,
        qutb_box_w: null,
        qutb_box_h: null,
        qutb_bag_l: null,
        qutb_bag_w: null,
        qutb_qpb: null,
        qutb_pricer: null,
        qutb_pridate: null,
        qutb_moq: null,
        imge_id: null,
        imge_url: '',
        imge_urlO: '',
        prod_suffix: null,
        smpl_list: [],
        qutb_qtso_sala_list: [],
        qutb_supp_list: [],
        qutbMold: {
          mold_cost: null,
          mold_piece: null,
          mold_out: null,
          mold_make_day: null,
          mold_smpl_day: null,
          mold_day_output: null,
          mold_moq: null
        }
      },
      uploadUrl: helper.modePrepend(imgeAPI.uploadImage),
      dataBody: {
        file: /\.(png|jpg|gif|jpeg)$/,
        cut: true,
        keep: false,
        x: 400,
        y: 400
      },
      imgForm: {
        imge_id: null,
        imge_url: null,
        imge_urlO: null
      },
      show: false,
      isShow: true,
      btn: {},
      stffForm: {},
      loadingFlag: true,
      dialogVisible: false,
      dialogVisible1: false,
      dialogVisible2: false,
      activeName: 'first',
      qutbStppList: [],
      users2: [],
      numflag: 1,
      qutbMadeType: [
        { id: 1, label: '尼龙' },
        { id: 2, label: 'TPR/TPE' },
        { id: 3, label: '橡胶' }
      ],
      suppSelList: [], //供应商 选择后list
      selectBom: [],
      selectAccess: [],
      selectOffeer: [],
      dialogTableData: [],
      dialogTableProperties: dialogTableProperties
    };
  },
  computed: {
    qutb_weight() {
      //原材料tab中的用量之和
      let temp = objectArrayReduce(
        this.qutbForm.qutb_qtso_mtrl_list?.filter(x => x.destroy_flag !== 1),
        'qutb_qtso_num'
      );
      this.qutbForm.qutb_weight = temp;
      return temp;
    }
  },
  created() {
    this.initData();
  },
  methods: {
    keep4Decimal,
    keep2Decimal,
    keepNumber,
    initData() {
      this.loadingFlag = true;
      this.getqutbInfo();
      this.getUsers();
    },
    //启用编辑
    openEdit() {
      this.isShow = false;
      this.$emit('isShow', this.isShow);
    },
    //撤销编辑
    closeEdit() {
      this.$confirm('是否撤销编辑?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      })
        .then(() => {
          this.isShow = true;
          this.$emit('isShow', this.isShow);
          this.show = true;
          this.initData();
          this.$message({
            type: 'info',
            message: '已撤销编辑!'
          });
        })
        .catch(() => {});
    },
    //提交表单
    submit(formName) {
      this.$confirm('确认保存?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          let dynamicUTable1 = await this.$refs.QutbBom.$refs.multiTable.fullValidateMsg();
          let dynamicUTable2 = await this.$refs.QutbAccess.$refs.multiTable.fullValidateMsg();
          let dynamicUTable3 = await this.$refs.QutbOffer.$refs.multiTable.fullValidateMsg();
          this.$refs[formName].validate((valid, object) => {
            if (valid && !dynamicUTable1 && !dynamicUTable2 && !dynamicUTable3) {
              this.saveInfo();
            } else {
              let tempStr = '';
              if (dynamicUTable1) tempStr += `原材料子表-${dynamicUTable1}`;
              if (dynamicUTable2) tempStr += `辅助材料子表-${dynamicUTable2}`;
              if (dynamicUTable3) tempStr += `人工工资子表-${dynamicUTable3}`;
              requiredReminderAndUGrid(object, tempStr);
            }
          });
        })
        .catch(err => console.error(err));
    },
    // 保存
    saveInfo() {
      const props = JSON.parse(UrlEncode.decode(this.$route.query.key));
      let qutbForm = JSON.parse(JSON.stringify(this.qutbForm));
      qutbForm.qutb_pridate = parseInt(new Date(qutbForm.qutb_pridate).getTime() / 1000);
      qutbForm.qutb_id = props.form_id;
      qutbForm.personal = this.$cookies.get('userInfo').personal;
      qutbForm.qutb_qtso_mtrl_list = qutbForm.qutb_qtso_mtrl_list.filter(x => (x.qutb_qtso_id ? true : !x.qutb_qtso_id && !x.destroy_flag));
      qutbForm.qutb_qtso_accmtrl_list = qutbForm.qutb_qtso_accmtrl_list.filter(x => (x.qutb_qtso_id ? true : !x.qutb_qtso_id && !x.destroy_flag));
      qutbForm.qutb_qtso_sala_list = qutbForm.qutb_qtso_sala_list.filter(x => (x.qutb_qtso_id ? true : !x.qutb_qtso_id && !x.destroy_flag));
      postNoCatch(qutbAPI.editQutb, qutbForm).then(({ data }) => {
        this.$message.success('保存成功!');
        this.isShow = true;
        this.$emit('isShow', this.isShow);
        this.initData();
      });
    },
    clickPrint() {
      this.$confirm('确定打印?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.isLoading = true;
          const str = this.qutbForm.qutb_id;
          get(qutbAPI.exportQutbPdfById, { qutb_id: str })
            .then(res => {
              if (res.data.code === 0) {
                if (process.env.NODE_ENV === 'production') {
                  window.open('/erpfile' + res.data.data.pdf_url + '.pdf');
                } else {
                  window.open('/file' + res.data.data.pdf_url + '.pdf');
                }

                this.isLoading = false;
                return;
              }
              this.$message.error(res.data.msg);
              this.isLoading = false;
            })
            .catch(res => {
              this.$message.error(res.data.msg);
              this.isLoading = false;
            });
        })
        .catch(() => {});
    },
    //导出 下载·
    clickExport() {
      let requestBody = { type: 1, qutb_id: this.qutbForm.qutb_id };
      qutbAPI.export_qutb(requestBody).then(({ data }) => {
        downloadFile({ fileUrl: data.url, fileName: data.fileName, type: '.xlsx' });
      });
    },
    // 刷新按钮
    buttonRefresh() {
      this.initData();
    },
    // 导入供应商
    importSupp() {
      this.dialogVisible = true;
    },
    // 取消选择供应商
    childSuppChanel() {
      this.dialogVisible = false;
    },
    handlePaste(event) {
      if (!this.isShow) {
        const items = (event.clipboardData || window.clipboardData).items;
        let file = null;
        if (!items || items.length === 0) {
          this.$message.error('当前浏览器不支持本地');
          return;
        }
        // 搜索剪切板items
        for (let i = 0; i < items.length; i++) {
          if (items[i].type.indexOf('image') !== -1) {
            file = items[i].getAsFile();
            break;
          }
        }
        if (!file) {
          this.$message.error('粘贴内容非图片');
          return;
        }
        this.$refs.uploadMutiple.handleStart(file); // 将粘贴过来的图片加入预上传队列
        this.$refs.uploadMutiple.submit(); // 提交图片上传队列
      } else {
        this.$message.warning('请先编辑');
      }
    },
    // 原面料删除
    deleteBom() {
      if (this.selectBom.length === 0) return this.$message.warning('请选择一条数据!');
      this.selectBom.forEach(item => (item.destroy_flag = 1));
    },
    // 辅料选中删除
    deleteAccess() {
      if (this.selectAccess.length === 0) return this.$message.warning('请选择一条数据!');
      this.selectAccess.forEach(item => (item.destroy_flag = 1));
    },
    // 人工工资选中删除
    deleteOffer() {
      if (this.selectOffeer.length === 0) return this.$message.warning('请选择一条数据!');
      this.selectOffeer.forEach(item => (item.destroy_flag = 1));
    },
    // 原面料删除选中
    handleSelectionChangeBom(val) {
      this.selectBom = val;
    },
    // 辅料选中删除
    handleSelectionChangeAccess(val) {
      this.selectAccess = val;
    },
    // 人工工资选中删除
    handleSelectionChangeOffer(val) {
      this.selectOffeer = val;
    },
    // 导入原面料、辅料和工资
    importBom(val) {
      this.numflag = val;
      this.dialogVisible1 = true;
      setTimeout(() => {
        this.$refs.QutbMacerList.$refs.multiTable.clearSelection();
        this.$refs.QutbMacerList.searchForm.qtso_type = null;
        this.$refs.QutbMacerList.initData();
      }, 500);
    },
    // 导入原面料、辅料和工资确认选择
    childQtso(originVal) {
      const val = originVal.map(item => {
        if (this.numflag === 1) {
          return { destroy_flag: null, ...this.$refs.QutbBom.$refs.multiTable.originalObject, ...item };
        } else if (this.numflag === 2) {
          return { destroy_flag: null, ...this.$refs.QutbAccess.$refs.multiTable.originalObject, ...item };
        } else if (this.numflag === 3) {
          return { destroy_flag: null, ...this.$refs.QutbOffer.$refs.multiTable.originalObject, ...item };
        }
      });

      // 根据numflag决定目标数组
      const targetListMap = {
        1: this.qutbForm.qutb_qtso_mtrl_list.filter(x => x.destroy_flag !== 1),
        2: this.qutbForm.qutb_qtso_accmtrl_list.filter(x => x.destroy_flag !== 1),
        3: this.qutbForm.qutb_qtso_sala_list.filter(x => x.destroy_flag !== 1)
      };

      const targetList = targetListMap[this.numflag] || [];

      function generateUniqueName(baseName, existingNames) {
        let suffix = 1;
        let newName = baseName;
        while (existingNames.includes(newName)) {
          newName = `${baseName}${suffix}`;
          suffix++;
        }
        return newName;
      }

      switch (this.numflag) {
        case 1:
          if (targetList.length === 0) {
            this.qutbForm.qutb_qtso_mtrl_list = this.qutbForm.qutb_qtso_mtrl_list.concat(val);
          } else {
            const uniqueItems1 = val.filter(newItem => !targetList.some(existingItem => newItem.qtso_id === existingItem.qtso_id));
            this.qutbForm.qutb_qtso_mtrl_list = this.qutbForm.qutb_qtso_mtrl_list.concat(uniqueItems1);
          }
          break;
        case 2:
          const uniqueItems2 = val.filter(newItem => {
            return newItem.qtso_type === 9 || !targetList.some(existingItem => newItem.qtso_id === existingItem.qtso_id && newItem.qtso_type !== 9);
          });
          uniqueItems2.forEach(item => {
            const namesWithCurrent = targetList.map(entry => entry.qtso_name);
            item.qtso_name = generateUniqueName(item.qtso_name, namesWithCurrent);
            this.qutbForm.qutb_qtso_accmtrl_list.push(item);
          });
          break;
        case 3:
          val.forEach(item => {
            const namesWithCurrent = targetList.filter(x => x.destroy_flag !== 1).map(entry => entry.qtso_name);
            item.qtso_name = generateUniqueName(item.qtso_name, namesWithCurrent);
            this.qutbForm.qutb_qtso_sala_list.push(item);
          });
          break;
      }
    },
    // 关闭导入原面料、辅料和工资确认选择
    childQtsoChanel() {
      this.dialogVisible1 = false;
    },
    //表单重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    // 成功上传
    handleAvatarSuccess(res) {
      if (res.code === 0) {
        let updateTime = JSON.parse(JSON.stringify(new Date().getTime()));
        let urlEng = JSON.parse(JSON.stringify(res.data.imge_url));
        this.qutbForm.imge_id = res.data.imge_id;
        this.qutbForm.imge_url = this.helper.picUrl(urlEng, 'm', updateTime);
        this.qutbForm.imge_urlO = this.helper.picUrl(urlEng, 'l', updateTime);
      } else {
        let mg = res.msg;
        let tp = 'error';
        this.$message({ message: mg, type: tp });
      }
    },
    // 上传头像前
    beforeAvatarUpload(res) {
      const isLt5M = res.size / 1024 / 1024 < 10;
      if (!isLt5M) {
        this.$message.error('图片大小不得超过 10MB!');
        return false;
      }
    },
    // 获取核价接收人
    getUsers() {
      get(stffAPI.getAllStffsV1, { dept_id: 7 })
        .then(res => {
          if (res.data.code === 0) {
            this.users2 = res.data.data;
          }
        })
        .catch(err => {
          this.$message.error(err.data.msg);
        });
    },
    //供应商 确认选择
    childSmpl(val) {
      let value = val;
      value = value.filter(item => this.qutbForm.qutb_supp_list.every(item1 => item.supp_id !== item1.supp_id));
      this.qutbForm.qutb_supp_list = this.qutbForm.qutb_supp_list.concat(value);
      this.dialogVisible = false;
    },
    handleSelectionChange(val) {
      this.suppSelList = val;
    },
    // 获取表单信息
    getqutbInfo() {
      const props = JSON.parse(UrlEncode.decode(this.$route.query.key));
      this.qutbForm.qutb_id = props.form_id;
      getNoCatch(qutbAPI.getQutbById, { qutb_id: this.qutbForm.qutb_id }).then(res => {
        this.qutbForm = res.data.form;
        this.btn = res.data.btn;
        const { stff_name, dept_name, dept_team_name, user_id, dept_id, stff_id, imge_id, imge_url, qutb_pridate } = this.qutbForm;
        this.stffForm = { stff_name, dept_name, dept_team_name, user_id, dept_id, stff_id };
        let urlEng = JSON.parse(JSON.stringify(imge_url));
        const updateTime = new Date().getTime();
        this.qutbForm = {
          ...this.qutbForm,
          imge_url: this.helper.picUrl(urlEng, 'm', updateTime),
          imge_urlO: this.helper.picUrl(urlEng, 'l', updateTime),
          imge_id: imge_id || 0 // 简化imge_id的赋值逻辑
        };
        if (this.show) {
          Object.assign(this.qutbForm, {
            imge_id: this.imgForm.imge_id,
            imge_url: this.imgForm.imge_url,
            imge_urlO: this.imgForm.imge_urlO
          });
          this.show = false;
        }
        this.imgForm = {
          imge_url: this.qutbForm.imge_url,
          imge_id: this.qutbForm.imge_id,
          imge_urlO: this.qutbForm.imge_urlO
        };
        this.qutbForm.qutb_pridate = qutb_pridate ? Number(qutb_pridate) * 1000 : null;
        setTimeout(() => {
          this.loadingFlag = false;
        }, 500);
      });
    },
    calcQutbAccessMoney() {
      this.qutbForm.qutb_qtso_accmtrl_list.forEach(item => {
        this.$refs.QutbAccess.clacQutbMoney(item);
      });
    },
    qutb_qtso_num() {
      let find = this.qutbForm.qutb_qtso_accmtrl_list?.find(x => x.qtso_name === '成品损耗');
      if (!find) return;
      // 成品损耗用量计算！公式 =（原材料总计 + 炼胶人工金额 + 注塑人工金额 + 粘胶人工金额 + 印刷人工金额+ 炼胶电费金额 + 注塑电费金额 + 粘胶电费金额  ）/ 100
      let totalRawMaterials = objectArrayReduce(this.qutbForm.qutb_qtso_mtrl_list, 'qutb_money');
      let amountOfRubberRefiningLabor = 0,
        injectionMoldingLaborAmount = 0,
        adhesiveLaborCost = 0,
        printingLaborCost = 0;
      let amountOfElectricityCostForRubberRefining = 0,
        injectionMoldingElectricityFeeAmount = 0,
        adhesiveElectricityFeeAmount = 0;
      // 辅助材料电费统计
      this.qutbForm.qutb_qtso_accmtrl_list
        ?.filter(x => x.destroy_flag !== 1)
        .forEach(item => {
          switch (item.qtso_name.replace(/\d+/g, '')) {
            case '炼胶电费':
              amountOfElectricityCostForRubberRefining += Number(item.qutb_money);
              break;
            case '注塑电费':
              injectionMoldingElectricityFeeAmount += Number(item.qutb_money);
              break;
            case '粘胶电费':
              adhesiveElectricityFeeAmount += Number(item.qutb_money);
              break;
          }
        });
      // 人工工资统计
      this.qutbForm.qutb_qtso_sala_list
        ?.filter(x => x.destroy_flag !== 1)
        .forEach(item => {
          switch (item.qtso_name.replace(/\d+/g, '')) {
            case '炼胶人工':
              amountOfRubberRefiningLabor += Number(item.qutb_money);
              break;
            case '注塑人工':
              injectionMoldingLaborAmount += Number(item.qutb_money);
              break;
            case '粘胶人工':
              adhesiveLaborCost += Number(item.qutb_money);
              break;
            case '印刷人工':
              printingLaborCost += Number(item.qutb_money);
              break;
          }
        });
      let temp = new BigNumber(totalRawMaterials)
        .plus(amountOfRubberRefiningLabor)
        .plus(injectionMoldingLaborAmount)
        .plus(adhesiveLaborCost)
        .plus(printingLaborCost)
        .plus(amountOfElectricityCostForRubberRefining)
        .plus(injectionMoldingElectricityFeeAmount)
        .plus(adhesiveElectricityFeeAmount)
        .div(100);
      if (!temp.isFinite()) {
        find.qutb_qtso_num = 0;
        return 0;
      } else {
        find.qutb_qtso_num = temp.toFixed(2);
        this.$refs.QutbAccess.changeFinishedProductLoss();
        return temp.toFixed(2);
      }
    },
    dialogDbClick(row) {
      this.qutbForm.fequ_no = row.fequ_no;
      this.qutbForm.requ_no = row.requ_no;
      this.qutbForm.prod_name = row.prod_name;
      this.qutbForm.cust_abbr = row.cust_abbr;
      this.qutbForm.prod_no = row.prod_no;
      this.qutbForm.prod_suffix = row.prod_suffix;
      this.qutbForm.supp_abbr = row.supp_abbr;
      this.qutbForm.prod_unit = row.prod_unit;
    },
    getDialogData() {
      getNoCatch(qutbAPI.get_fequ_part, this.$refs.dialogTable.searchForm).then(({ data }) => {
        this.dialogTableData = data;
      });
    }
  }
};
</script>

<style scoped lang="scss">
::v-deep .el-input__inner {
  height: 28px;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader ::v-deep .el-upload--text {
  width: 136px;
  height: 136px;
}

.avatar-uploader .el-upload:hover {
  border-color: $color-primary;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: $color-uploader-icon;
  width: 136px;
  height: 136px;
  line-height: 136px;
  text-align: center;
}

.avatar {
  width: 136px;
  height: 136px;
  display: block;
}

.vd_bortop {
  border-top: #dcdfe6 solid 1px;
  padding-top: 20px;
}

.vd_bort {
  border-top: dashed 1px #dcdfe6;
  padding-top: 20px;
}

.vd_mrt1 {
  margin-top: 1px;
}

.vd_edhea {
  position: relative;
}

.vd_export {
  position: absolute;
  top: 0;
  left: 65px;
}

.vd_export1 {
  position: absolute;
  top: 0;
  left: 145px;
}

.vd_export2 {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  right: 120px;
}

::v-deep .el-upload--text {
  height: 200px;
  width: 200px;
}

::v-deep .el-upload-dragger {
  width: 200px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.vd_dash {
  width: 100%;
  height: 1px;
  border-top: 1px dashed #e9e9e9;
  margin: 10px 0;
}

.vd_but {
  margin-bottom: 10px;
}
.vd_dis {
  display: flex;
  align-items: center;
}
.vd_flex_right {
  cursor: pointer;
  box-sizing: border-box;
  padding-left: 10px;
}
</style>
