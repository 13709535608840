import { render, staticRenderFns } from "./QutbEditAbout.vue?vue&type=template&id=38cc1c37&scoped=true&"
import script from "./QutbEditAbout.vue?vue&type=script&lang=js&"
export * from "./QutbEditAbout.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38cc1c37",
  null
  
)

export default component.exports