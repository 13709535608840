<template>
  <div>
    <DynamicUTable
      ref="multiTable"
      :is-show="isShow"
      :tableData="cTableData"
      :columns="qutbOfferTableProperties"
      @selection-change="handleSelectionChange"
      :needPagination="false"
      :needSearch="false"
      :row-click="(row, column, event) => (currentRow = row)"
    >
    </DynamicUTable>
    <div class="vd_total">
      总计:<span class="vd_color">{{ totalPrice }}</span>
    </div>
  </div>
</template>

<script>
import { get } from '@api/request';
import { optnAPI } from '@api/modules/optn';
import { keep2Decimal, keep4Decimal } from '@assets/js/regExUtil';
import { BigNumber } from 'bignumber.js';
import { objectArrayReduce } from '@assets/js/arrayUtils';
import DynamicUTable from '@/components/table/DynamicUTable.vue';
import { qutbOfferTableProperties } from '@/views/DevelopManagement/QutbManage/qutb';
// 二部报价明细人工工资
export default {
  name: 'QutbBom',
  components: {
    DynamicUTable
  },
  props: {
    qutbForm: {
      type: Object,
      required: true
    },
    isShow: { type: Boolean, default: false }
  },
  data() {
    return {
      smplFlag: 0,
      validatePass: (rule, value, callback) => {
        let that = this;
        if (value) {
          if (that.smplFlag === 2) {
            callback(new Error(''));
          } else if (that.smplFlag === 0) {
            callback(true);
          }
        }
      },
      selectionsList: [],
      smplType: [
        { id: 1, label: '新款' },
        { id: 2, label: '老款' },
        { id: 3, label: '修改款' }
      ],
      selectFlag: false,
      beloType: [],
      qtsoTypeList: [
        { value: 1, label: '原材料' },
        { value: 2, label: '香精' },
        { value: 3, label: '夜光粉' },
        { value: 4, label: '颗粒' },
        { value: 5, label: '矿粉' },
        { value: 6, label: '色粉' },
        { value: 7, label: '成品损耗' },
        { value: 8, label: '辅助料材' },
        { value: 9, label: '水电费' },
        { value: 10, label: '运费' },
        { value: 11, label: '纸箱' },
        { value: 12, label: '塑料袋' },
        { value: 13, label: '人工工资' }
      ],
      currentRow: {},
      qutbOfferTableProperties: qutbOfferTableProperties
    };
  },
  computed: {
    cTableData() {
      return this.qutbForm.qutb_qtso_sala_list.filter(x => x.destroy_flag !== 1);
    },
    qutb_money() {
      let { qtso_name, qtso_spec, qtso_price, qutb_qtso_num, qutb_qtso_loss, qtso_daypr, qutb_daily_output } = this.currentRow;
      let temp;
      if (qtso_name === '炭灰人工' || qtso_spec === '5毫米脱模机') {
        if (qtso_name === '炼胶人工') {
          // 计算公式=（原材料tab中的胶料用量+胶料损耗）*单价；四舍五入保留4位小数
          let find = this.qutbForm.qutb_qtso_mtrl_list.find(x => x.qtso_name === '胶料');
          if (find) {
            let { qutb_qtso_num, qutb_qtso_loss, qtso_price } = find;
            temp = new BigNumber(qutb_qtso_num).plus(qutb_qtso_loss).times(qtso_price);
          } else temp = new BigNumber(NaN);
        } else {
          // 1.按日计价为否时：金额 = 单价 * （用量+ 损耗)
          // 2.按日计价为是时：金额 = (单价/日产量) * （用量 + 损耗)；四舍五入保留4位小数
          if (qtso_daypr === 0) temp = new BigNumber(qutb_qtso_num).plus(qutb_qtso_loss).times(qtso_price);
          else temp = new BigNumber(new BigNumber(qtso_price).div(qutb_daily_output)).times(new BigNumber(qutb_qtso_num).plus(qutb_qtso_loss));
        }
      } else {
        // 金额=（单价/日产量）*（用量+损耗）；四舍五入保留4位小数
        temp = new BigNumber(new BigNumber(qtso_price).div(qutb_daily_output)).times(new BigNumber(qutb_qtso_num).plus(qutb_qtso_loss));
      }
      if (!temp.isFinite()) {
        this.currentRow.qutb_money = 0;
        return '自动计算';
      }
      this.currentRow.qutb_money = temp.toFixed(4);
      this.$emit('setFinishedProductLoss');
      return temp.toFixed(4);
    },
    totalPrice() {
      return objectArrayReduce(
        this.qutbForm.qutb_qtso_sala_list?.filter(x => x.destroy_flag !== 1),
        'qutb_money'
      );
    }
  },
  created() {
    this.initData();
    let temp = ['日产量', '单价', '用量', '损耗'];
    this.qutbOfferTableProperties.forEach(x => {
      if (temp.includes(x.label)) {
        x.subItem.change = (val, row, options) => this.calcQutbMoney(row);
      }
    });
  },
  methods: {
    keep4Decimal,
    keep2Decimal,
    initData() {
      this.getContry();
    },
    // 获取打样部门
    getContry() {
      get(optnAPI.getOptnByPermId, { perm_id: 10009 })
        .then(res => {
          if (res.data.code === 0) {
            let beloType = res.data.data.form.optn_cntt_list;
            for (let item of beloType) {
              item.param2 = Number(item.param2);
            }
            this.beloType = beloType;
          }
        })
        .catch(() => {});
    },
    calcQutbMoney(row) {
      let { qtso_name, qtso_spec, qtso_price, qutb_qtso_num, qutb_qtso_loss, qtso_daypr, qutb_daily_output } = row;
      let temp;
      if (qtso_name === '炭灰人工' || qtso_spec === '5毫米脱模机') {
        if (qtso_name === '炼胶人工') {
          // 计算公式=（原材料tab中的胶料用量+胶料损耗）*单价；四舍五入保留4位小数
          let find = this.qutbForm.qutb_qtso_mtrl_list.filter(x => x.destroy_flag !== 1).find(x => x.qtso_name === '胶料');
          if (find) {
            let { qutb_qtso_num, qutb_qtso_loss, qtso_price } = find;
            temp = new BigNumber(qutb_qtso_num).plus(qutb_qtso_loss).times(qtso_price);
          } else temp = new BigNumber(NaN);
        } else {
          // 1.按日计价为否时：金额 = 单价 * （用量+ 损耗)
          // 2.按日计价为是时：金额 = (单价/日产量) * （用量 + 损耗)；四舍五入保留4位小数
          if (qtso_daypr === 0) temp = new BigNumber(qutb_qtso_num).plus(qutb_qtso_loss).times(qtso_price);
          else temp = new BigNumber(new BigNumber(qtso_price).div(qutb_daily_output)).times(new BigNumber(qutb_qtso_num).plus(qutb_qtso_loss));
        }
      } else {
        // 金额=（单价/日产量）*（用量+损耗）；四舍五入保留4位小数
        temp = new BigNumber(new BigNumber(qtso_price).div(qutb_daily_output)).times(new BigNumber(qutb_qtso_num).plus(qutb_qtso_loss));
      }
      if (!temp.isFinite()) {
        row.qutb_money = 0;
        return;
      }
      row.qutb_money = temp.toFixed(4);
      this.$emit('setFinishedProductLoss');
    },
    //bom清单选择框
    handleSelectionChange(selection) {
      this.selectionsList = [];
      this.selectionsList = selection;
      this.$emit('handleSelectionChangeOffer', this.selectionsList);
    }
  }
};
</script>

<style scoped lang="scss">
.vd_total {
  font-size: 24px;
  text-align: right;
  width: 100%;
  padding-right: 30px;
  box-sizing: border-box;
}

.vd_col {
  color: #f00;
}

.vd_color {
  color: #c70e0e;
}
</style>
