<template>
  <div>
    <el-table :data="qutbForm.qutb_supp_list" border @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="48" align="center"></el-table-column>
      <el-table-column label="序号" width="48" align="center">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column label="供应商简称">
        <template slot-scope="scope">
          <el-form-item label-width="0" :prop="'qutb_supp_list.' + scope.$index + '.supp_abbr'">
            <el-input
              disabled
              v-model="qutbForm.qutb_supp_list[scope.$index].supp_abbr"
              maxlength="30"
              show-word-limit
              placeholder="暂无供应商简称"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column>
        <template slot="header">
          <span class="vg_deep_red">含税报价</span>
        </template>
        <template slot-scope="scope">
          <el-form-item label-width="0" :prop="'qutb_supp_list.' + scope.$index + '.qutb_supp_total'" :rules="[{ required: true }]">
            <el-input
              v-model="qutbForm.qutb_supp_list[scope.$index].qutb_supp_total"
              maxlength="9"
              show-word-limit
              placeholder="暂无含税报价"
              @input="
                qutbForm.qutb_supp_list[scope.$index].qutb_supp_total = helper.keepTNum2(
                  qutbForm.qutb_supp_list[scope.$index].qutb_supp_total
                )
              "
              @change="
                qutbForm.qutb_supp_list[scope.$index].qutb_supp_total = helper.calcPrice(
                  qutbForm.qutb_supp_list[scope.$index].qutb_supp_total,
                  4,
                  10000
                )
              "
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column>
        <template slot="header">
          <span class="vg_deep_red">模具费</span>
        </template>
        <template slot-scope="scope">
          <el-form-item label-width="0" :prop="'qutb_supp_list.' + scope.$index + '.qutb_supp_mcost'" :rules="[{ required: true }]">
            <el-input
              v-model="qutbForm.qutb_supp_list[scope.$index].qutb_supp_mcost"
              maxlength="9"
              show-word-limit
              placeholder="暂无模具费"
              @input="
                qutbForm.qutb_supp_list[scope.$index].qutb_supp_mcost = helper.keepTNum2(
                  qutbForm.qutb_supp_list[scope.$index].qutb_supp_mcost
                )
              "
              @change="
                qutbForm.qutb_supp_list[scope.$index].qutb_supp_mcost = helper.calcPriceT(
                  qutbForm.qutb_supp_list[scope.$index].qutb_supp_mcost,
                  2,
                  1000000
                )
              "
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column>
        <template slot="header">
          <span class="vg_deep_red">模具付数</span>
        </template>
        <template slot-scope="scope">
          <el-form-item label-width="0" :prop="'qutb_supp_list.' + scope.$index + '.qutb_supp_mpc'" :rules="[{ required: true }]">
            <el-input
              v-model="qutbForm.qutb_supp_list[scope.$index].qutb_supp_mpc"
              maxlength="10"
              @input="
                qutbForm.qutb_supp_list[scope.$index].qutb_supp_mpc = helper.pureNumber(qutbForm.qutb_supp_list[scope.$index].qutb_supp_mpc)
              "
              show-word-limit
              placeholder="暂无模具付数"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { optnAPI } from '@api/modules/optn';

export default {
  name: 'QutbBom',
  props: {
    qutbForm: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      smplFlag: 0,
      validatePass: (rule, value, callback) => {
        let that = this;
        if (value) {
          if (that.smplFlag === 2) {
            callback(new Error(''));
          } else if (that.smplFlag === 0) {
            callback(true);
          }
        }
      },
      selectionsList: [],
      smplType: [
        { id: 1, label: '新款' },
        { id: 2, label: '老款' },
        { id: 3, label: '修改款' }
      ],
      selectFlag: false,
      beloType: []
    };
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      this.getContry();
    },
    // 获取打样部门
    getContry() {
      get(optnAPI.getOptnByPermId, { perm_id: 10009 })
        .then(res => {
          if (res.data.code === 0) {
            let beloType = res.data.data.form.optn_cntt_list;
            for (let item of beloType) {
              item.param2 = Number(item.param2);
            }
            this.beloType = beloType;
          }
        })
        .catch(() => {});
    },
    // 跳转样品工单
    jumpPodrProd(row) {
      const permId = this.$route.query.perm_id;
      this.jump('/smpl_edit', { perm_id: 112, form_id: row.smpl_id });
    },
    // 样品类型变化
    smplTypeChange(scope) {
      if (scope.row.smpl_type !== 2) {
        if (scope.row.smpl_no && scope.row.smpl_type) {
          this.selectFlag = true;
          this.getSmplList(scope.row.smpl_no, scope.row.smpl_type, scope.row.smpl_id);
          setTimeout(() => {
            if (this.smplFlag === 2) {
              scope.row.smpl_no = '';
              this.$message({ message: '样品编号不能重复', type: 'error' });
            }
            this.selectFlag = false;
          }, 800);
        }
      }
      // if(scope.row.smpl_type !== 2){
      //   if(scope.row.smpl_no && scope.row.smpl_type){
      //     this.selectFlag = true;
      //     this.getSmplList(scope.row.smpl_no,scope.row.smpl_type,scope.row.smpl_id);
      //     setTimeout(() => {
      //       if(this.smplFlag === 2){
      //         this.$refs["smplInput"+scope.$index].focus();
      //         setTimeout(() => {
      //           this.$refs["smplInput"+scope.$index].blur();
      //           this.selectFlag = false;
      //           this.smplFlag = 1;
      //         }, 300);
      //       }else{
      //         this.selectFlag = false;
      //         this.smplFlag = 1;
      //           this.$refs["smplInput"+scope.$index].focus()
      //           setTimeout(() => {
      //             this.$refs["smplInput"+scope.$index].blur()
      //           }, 300);
      //       }
      //     }, 800);
      //   }
      // }else{
      //   this.smplFlag = 1
      //   setTimeout(() => {
      //     this.$refs["smplInput"+scope.$index].focus()
      //     setTimeout(() => {
      //       this.$refs["smplInput"+scope.$index].blur()
      //     }, 300);
      //   }, 800);
      // }
    },
    //bom清单选择框
    handleSelectionChange(selection) {
      this.selectionsList = [];
      this.selectionsList = selection;
      this.$emit('handleSelectionChange', this.selectionsList);
    }
  }
};
</script>

<style scoped lang="scss">
.vd_total {
  font-size: 24px;
  text-align: right;
  width: 100%;
  padding-right: 30px;
  box-sizing: border-box;
}
</style>
