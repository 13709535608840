import { keep2Decimal, keep4Decimal } from '@assets/js/regExUtil';

const qtsoTypeList = [
  { value: 1, label: '原材料' },
  { value: 2, label: '香精' },
  { value: 3, label: '夜光粉' },
  { value: 4, label: '颗粒' },
  { value: 5, label: '矿粉' },
  { value: 6, label: '色粉' },
  { value: 7, label: '成品损耗' },
  { value: 8, label: '辅助料材' },
  { value: 9, label: '水电费' },
  { value: 10, label: '运费' },
  { value: 11, label: '纸箱' },
  { value: 12, label: '塑料袋' },
  { value: 13, label: '人工工资' }
];
const formatQtsoType = val => {
  return qtsoTypeList.find(({ value }) => val === value)?.label || '';
};
export const qutbBomTableProperties = [
  {
    label: '素材类型',
    prop: 'qtso_type',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: true,
    formatter: val => formatQtsoType(val)
  },
  { label: '素材名称', prop: 'qtso_name', itemType: 'input', input: false, sortable: false, widthAuto: true },
  { label: '素材规格', prop: 'qtso_spec', itemType: 'input', input: false, sortable: false, widthAuto: true },
  {
    label: '添加量%',
    prop: 'add_quantity',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: true,
    subItem: { type: 'input', maxLength: 7, input: (val, row) => (row.add_quantity = keep4Decimal(val)) }
  },
  {
    label: '损耗',
    prop: 'qutb_qtso_loss',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: true,
    subItem: { type: 'input', maxLength: 6, input: (val, row) => (row.qutb_qtso_loss = keep4Decimal(val)) }
  },
  {
    label: '用量',
    prop: 'qutb_qtso_num',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: true,
    subItem: {
      type: 'input',
      maxLength: 8,
      input: (val, row) => (row.qutb_qtso_num = keep2Decimal(val)),
      disabled: row => ['橡胶香精', '尼龙香精', 'TPE香精', 'TPR香精', '橡胶夜光粉', '橡胶颗粒'].includes(row.qtso_name),
      skipCalc: row => ['胶料', '尼龙', 'TPR夜光粉', '尼龙矿粉', '尼龙色粉', 'TPE色粉'].includes(row.qtso_name)
    }
  },
  {
    label: '单价',
    prop: 'qtso_price',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: true,
    subItem: { type: 'input', maxLength: 10, input: (val, row) => (row.qtso_price = keep4Decimal(val)) }
  },
  { label: '金额', prop: 'qutb_money', itemType: 'input', input: false, sortable: false, widthAuto: true }
];
export const qutbAccessTableProperties = [
  {
    label: '素材类型',
    prop: 'qtso_type',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: true,
    formatter: val => formatQtsoType(val)
  },
  { label: '素材名称', prop: 'qtso_name', itemType: 'input', input: false, sortable: false, widthAuto: true },
  { label: '素材规格', prop: 'qtso_spec', itemType: 'input', input: false, sortable: false, widthAuto: true },
  {
    label: '按日计价',
    prop: 'qtso_daypr',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: true,
    formatter: val => (val === 1 ? '是' : '否')
  },
  {
    label: '日产量',
    prop: 'qutb_daily_output',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: true,
    subItem: { type: 'input', maxLength: 10, disabled: row => row.qtso_daypr !== 1, input: (val, row) => (row.qutb_daily_output = keep2Decimal(val)) }
  },
  {
    label: '单价',
    prop: 'qtso_price',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: true,
    subItem: { type: 'input', maxLength: 10, input: (val, row) => (row.qtso_price = keep4Decimal(val)) }
  },
  {
    label: '用量',
    prop: 'qutb_qtso_num',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: true,
    subItem: {
      type: 'input',
      maxLength: 8,
      input: (val, row) => (row.qutb_qtso_num = keep2Decimal(val)),
      disabled: row => ['成品损耗'].includes(row.qtso_name),
      skipCalc: row => !['成品损耗'].includes(row.qtso_name)
    }
  },
  {
    label: '损耗',
    prop: 'qutb_qtso_loss',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: true,
    subItem: { type: 'input', maxLength: 6, input: (val, row) => (row.qutb_qtso_loss = keep4Decimal(val)) }
  },
  { label: '金额', prop: 'qutb_money', itemType: 'input', input: false, sortable: false, widthAuto: true }
];
export const qutbOfferTableProperties = [
  {
    label: '素材类型',
    prop: 'qtso_type',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: true,
    formatter: val => formatQtsoType(val)
  },
  { label: '素材名称', prop: 'qtso_name', itemType: 'input', input: false, sortable: false, widthAuto: true },
  { label: '素材规格', prop: 'qtso_spec', itemType: 'input', input: false, sortable: false, widthAuto: true },
  {
    label: '按日计价',
    prop: 'qtso_daypr',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: true,
    formatter: val => (val === 1 ? '是' : '否')
  },
  {
    label: '日产量',
    prop: 'qutb_daily_output',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: true,
    subItem: { type: 'input', maxLength: 10, disabled: row => row.qtso_daypr !== 1, input: (val, row) => (row.qutb_daily_output = keep2Decimal(val)) }
  },
  {
    label: '单价',
    prop: 'qtso_price',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: true,
    subItem: { type: 'input', maxLength: 10, input: (val, row) => (row.qtso_price = keep4Decimal(val)) }
  },
  {
    label: '用量',
    prop: 'qutb_qtso_num',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: true,
    subItem: { type: 'input', maxLength: 8, input: (val, row) => (row.qutb_qtso_num = keep2Decimal(val)) }
  },
  {
    label: '损耗',
    prop: 'qutb_qtso_loss',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: true,
    subItem: { type: 'input', maxLength: 6, input: (val, row) => (row.qutb_qtso_loss = keep4Decimal(val)) }
  },
  { label: '金额', prop: 'qutb_money', itemType: 'input', input: false, sortable: false, widthAuto: true }
];
export const dialogTableProperties = [
  { label: '我司货号', prop: 'prod_no', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '更改号', prop: 'prod_suffix', itemType: 'input', input: false, sortable: false, widthAuto: true },
  { label: '样品名称', prop: 'prod_name', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '委托打样单号', prop: 'requ_no', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '工厂打样单号', prop: 'fequ_no', itemType: 'input', input: true, sortable: false, widthAuto: true }
];
