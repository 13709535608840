<template>
  <div>
    <table border="1px" width="100%" cellpadding="0" cellspacing="0" class="vd_table">
      <tr>
        <td class="vd_td_o vd_col">模具费</td>
        <td class="vd_td_t">
          <el-form-item label-width="0" :prop="'qutbMold.mold_cost'" :rules="[{ required: true, trigger: 'blur', message: ' ' }]">
            <el-input
              maxlength="9"
              v-model="qutbForm.qutbMold.mold_cost"
              @input="qutbForm.qutbMold.mold_cost = helper.keepTNum1(qutbForm.qutbMold.mold_cost)"
              @blur="qutbForm.qutbMold.mold_cost = helper.calcPrice(qutbForm.qutbMold.mold_cost, 4, 10000)"
              show-word-limit
              placeholder="暂无模具费"
            ></el-input>
            <!-- <el-form-item label-width="0" :prop="'qutbMold.mold_cost'">
            <el-input maxlength="11" v-model="qutbForm.qutbMold.mold_cost" @input="qutbForm.qutbMold.mold_cost = helper.keepTNum1(qutbForm.qutbMold.mold_cost)" show-word-limit placeholder="暂无模具费"></el-input> -->
          </el-form-item>
        </td>
      </tr>
      <tr>
        <td class="vd_td_o vd_col">模具付数</td>
        <td class="vd_td_t">
          <el-form-item label-width="0" :prop="'qutbMold.mold_piece'" :rules="[{ required: true, trigger: 'blur', message: ' ' }]">
            <el-input
              maxlength="10"
              v-model="qutbForm.qutbMold.mold_piece"
              @input="qutbForm.qutbMold.mold_piece = helper.keepTNum(qutbForm.qutbMold.mold_piece)"
              show-word-limit
              placeholder="暂无模具付数"
            ></el-input>
          </el-form-item>
        </td>
      </tr>
      <tr>
        <td class="vd_td_o vd_col">一出数量</td>
        <td class="vd_td_t">
          <el-form-item label-width="0" :prop="'qutbMold.mold_out'" :rules="[{ required: true, trigger: 'blur', message: ' ' }]">
            <el-input
              maxlength="10"
              v-model="qutbForm.qutbMold.mold_out"
              @input="qutbForm.qutbMold.mold_out = helper.keepTNum(qutbForm.qutbMold.mold_out)"
              show-word-limit
              placeholder="暂无一出数量"
            ></el-input>
          </el-form-item>
        </td>
      </tr>
      <tr>
        <td class="vd_td_o vd_col">开模周期</td>
        <td class="vd_td_t">
          <el-form-item label-width="0" :prop="'qutbMold.mold_make_day'" :rules="[{ required: true, trigger: 'blur', message: ' ' }]">
            <el-input
              maxlength="10"
              v-model="qutbForm.qutbMold.mold_make_day"
              @input="qutbForm.qutbMold.mold_make_day = helper.keepTNum(qutbForm.qutbMold.mold_make_day)"
              show-word-limit
              placeholder="暂无开模周期"
            ></el-input>
          </el-form-item>
        </td>
      </tr>
      <tr>
        <td class="vd_td_o vd_col">出样周期</td>
        <td class="vd_td_t">
          <el-form-item label-width="0" :prop="'qutbMold.mold_smpl_day'" :rules="[{ required: true, trigger: 'blur', message: ' ' }]">
            <el-input
              maxlength="10"
              v-model="qutbForm.qutbMold.mold_smpl_day"
              @input="qutbForm.qutbMold.mold_smpl_day = helper.keepTNum(qutbForm.qutbMold.mold_smpl_day)"
              show-word-limit
              placeholder="暂无出样周期"
            ></el-input>
          </el-form-item>
        </td>
      </tr>
      <tr>
        <td class="vd_td_o vd_col">预估日产量</td>
        <td class="vd_td_t">
          <el-form-item label-width="0" :prop="'qutbMold.mold_day_output'" :rules="[{ required: true, trigger: 'blur', message: ' ' }]">
            <el-input
              maxlength="10"
              @change="moldDayOutput"
              v-model="qutbForm.qutbMold.mold_day_output"
              @input="qutbForm.qutbMold.mold_day_output = helper.keepTNum(qutbForm.qutbMold.mold_day_output)"
              show-word-limit
              placeholder="暂无预估日产量"
            ></el-input>
          </el-form-item>
        </td>
      </tr>
      <tr>
        <td class="vd_td_o vd_col">起订量</td>
        <td class="vd_td_t">
          <el-form-item label-width="0" :prop="'qutbMold.mold_moq'" :rules="[{ required: true, trigger: 'blur', message: ' ' }]">
            <el-input
              maxlength="10"
              v-model="qutbForm.qutbMold.mold_moq"
              @input="qutbForm.qutbMold.mold_moq = helper.keepTNum(qutbForm.qutbMold.mold_moq)"
              show-word-limit
              placeholder="暂无起订量"
            ></el-input>
          </el-form-item>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  name: 'QutbMould',
  props: {
    qutbForm: {
      type: Object,
      required: true
    }
  },
  data() {
    return {};
  },
  methods: {
    moldDayOutput() {
      this.$emit('moldDayOutput');
    }
  }
};
</script>

<style scoped lang="scss">
table .vd_td_t {
  width: 67%;
  height: 30px;
  border-bottom: #e9e9e9 1px solid;
  border-right: #e9e9e9 1px solid;
  border-left: none;
  border-top: #e9e9e9 1px solid;
}
table .vd_td_o {
  width: 33%;
  padding-left: 5px;
  color: #606266;
  border-bottom: #e9e9e9 1px solid;
  border-right: #e9e9e9 1px solid;
  border-left: #e9e9e9 1px solid;
  border-top: none;
}
.vd_td_o:nth-of-type(1) {
  border-top: #e9e9e9 1px solid;
}
table {
  // border: #f3f3f3 1px solid;
  border: none;
}
.vd_th {
  text-align: left;
  height: 30px;
  border-top: #e9e9e9 1px solid;
  background-color: #f5f7fa;
  color: #909399;
  border: none;
}
.vd_col {
  color: #f00 !important;
}
</style>
