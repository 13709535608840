<template>
  <div>
    <DynamicUTable
      ref="multiTable"
      :is-show="isShow"
      :computed-data="{ qutb_qtso_num: qutb_qtso_num }"
      :columns="qutbBomTableProperties"
      @selection-change="handleSelectionChange"
      :needPagination="false"
      :needSearch="false"
      :tableData="cTableData"
      :row-click="(row, column, event) => (currentRow = row)"
    >
    </DynamicUTable>
    <div class="vd_total">
      总计:<span class="vd_color">{{ totalPrice }}</span>
    </div>
  </div>
</template>

<script>
import { get } from '@api/request';
import { optnAPI } from '@api/modules/optn';
import { BigNumber } from 'bignumber.js';
import { objectArrayReduce } from '@assets/js/arrayUtils';
import { keep2Decimal, keep4Decimal } from '@assets/js/regExUtil';
import { qutbBomTableProperties } from '@/views/DevelopManagement/QutbManage/qutb';
import DynamicUTable from '@/components/table/DynamicUTable.vue';
// 二部报价明细原材料
export default {
  name: 'QutbBom',
  components: { DynamicUTable },
  props: {
    qutbForm: {
      type: Object,
      required: true
    },
    isShow: { type: Boolean, default: false }
  },
  computed: {
    cTableData() {
      return this.qutbForm.qutb_qtso_mtrl_list.filter(x => x.destroy_flag !== 1);
    },
    qutb_money() {
      let { qtso_price, qutb_qtso_num, qutb_qtso_loss } = this.currentRow;
      let temp = new BigNumber(qutb_qtso_loss).plus(qutb_qtso_num).times(qtso_price);
      if (!temp.isFinite()) {
        this.currentRow.qutb_money = 0;
        return '自动计算';
      }
      this.currentRow.qutb_money = temp.toFixed(4);
      return temp.toFixed(4);
    },
    qutb_qtso_num() {
      let { qtso_name, add_quantity: cAddQuantity } = this.currentRow;
      let result = 0;
      switch (qtso_name) {
        // 橡胶香精用量 =（ 胶料用量 + 胶料损耗 ）* 添加量 / 100 四舍五入保留2位小数
        case '橡胶香精':
        case '橡胶夜光粉':
        case '橡胶颗粒':
          let xjxjfind = this.qutbForm.qutb_qtso_mtrl_list?.filter(x => x.destroy_flag !== 1).find(x => x.qtso_name === '胶料');
          if (xjxjfind) {
            let { qutb_qtso_num, qutb_qtso_loss, add_quantity } = xjxjfind;
            let temp = new BigNumber(qutb_qtso_num).plus(qutb_qtso_loss).times(cAddQuantity).div(100);
            if (temp.isFinite()) {
              result = temp.toFixed(2);
              this.currentRow.qutb_qtso_num = result;
            } else {
              result = 0;
              this.currentRow.qutb_qtso_num = 0;
            }
          } else result = 0;
          break;
        // 尼龙香精用量 = 尼龙用量 * 添加量 / 100；四舍五入保留2位小数
        case '尼龙香精':
          let nlxjfind = this.qutbForm.qutb_qtso_mtrl_list?.filter(x => x.destroy_flag !== 1).find(x => x.qtso_name === '尼龙');
          if (nlxjfind) {
            let { qutb_qtso_num, add_quantity } = nlxjfind;
            let temp = new BigNumber(qutb_qtso_num).times(cAddQuantity).div(100);
            if (temp.isFinite()) {
              result = temp.toFixed(2);
              this.currentRow.qutb_qtso_num = result;
            } else {
              result = 0;
              this.currentRow.qutb_qtso_num = 0;
            }
          } else result = 0;
          break;
        // TPE香精|TPR香精 用量 =（ 胶料用量 * 添加量）/100；四舍五入保留2位小数
        case 'TPE香精':
        case 'TPR香精':
          let tpexjfind = this.qutbForm.qutb_qtso_mtrl_list?.filter(x => x.destroy_flag !== 1).find(x => x.qtso_name === '胶料');
          if (tpexjfind) {
            let { qutb_qtso_num, add_quantity } = tpexjfind;
            let temp = new BigNumber(qutb_qtso_num).times(cAddQuantity).div(100);
            if (temp.isFinite()) {
              result = temp.toFixed(2);
              this.currentRow.qutb_qtso_num = result;
            } else {
              result = 0;
              this.currentRow.qutb_qtso_num = 0;
            }
          } else result = 0;
          break;
        /*        // 橡胶夜光粉用量 =（ 胶料用量 + 胶料损耗 ）* 0.02 * 0.3 计算结果四舍五入保留2位小数
        case '橡胶夜光粉':
          let xjygffind = this.qutbForm.qutb_qtso_mtrl_list?.filter(x => x.destroy_flag !== 1).find(x => x.qtso_name === '胶料');
          if (xjygffind) {
            let { qutb_qtso_num, qutb_qtso_loss } = xjygffind;
            let temp = new BigNumber(qutb_qtso_num).plus(qutb_qtso_loss).times(0.02).times(0.3);
            if (temp.isFinite()) {
              result = temp.toFixed(2);
              this.currentRow.qutb_qtso_num = result;
            } else {
              result = 0;
              this.currentRow.qutb_qtso_num = 0;
            }
          } else result = 0;
          break;
        // 橡胶颗粒用量 =（ 胶料用量 + 胶料损耗 ）* 0.15 * 0.15 四舍五入保留2位小数
        case '橡胶颗粒':
          let xjklfind = this.qutbForm.qutb_qtso_mtrl_list?.filter(x => x.destroy_flag !== 1).find(x => x.qtso_name === '胶料');
          if (xjklfind) {
            let { qutb_qtso_num, qutb_qtso_loss } = xjklfind;
            let temp = new BigNumber(qutb_qtso_num).plus(qutb_qtso_loss).times(0.15).times(0.15);
            if (temp.isFinite()) {
              result = temp.toFixed(2);
              this.currentRow.qutb_qtso_num = result;
            } else {
              result = 0;
              this.currentRow.qutb_qtso_num = 0;
            }
          } else result = 0;
          break;*/
      }
      return result;
    },
    totalPrice() {
      return objectArrayReduce(
        this.qutbForm.qutb_qtso_mtrl_list?.filter(x => x.destroy_flag !== 1),
        'qutb_money'
      );
    }
  },
  data() {
    return {
      smplFlag: 0,
      validatePass: (rule, value, callback) => {
        let that = this;
        if (value) {
          if (that.smplFlag === 2) {
            callback(new Error(''));
          } else if (that.smplFlag === 0) {
            callback(true);
          }
        }
      },
      selectionsList: [],
      qtsoTypeList: [
        { value: 1, label: '原材料' },
        { value: 2, label: '香精' },
        { value: 3, label: '夜光粉' },
        { value: 4, label: '颗粒' },
        { value: 5, label: '矿粉' },
        { value: 6, label: '色粉' },
        { value: 7, label: '成品损耗' },
        { value: 8, label: '辅助料材' },
        { value: 9, label: '水电费' },
        { value: 10, label: '运费' },
        { value: 11, label: '纸箱' },
        { value: 12, label: '塑料袋' }
      ],
      selectFlag: false,
      beloType: [],
      currentRow: {},
      qutbBomTableProperties: qutbBomTableProperties
    };
  },
  created() {
    this.initData();
    let temp = ['添加量%', '损耗', '用量'];
    this.qutbBomTableProperties.forEach(x => {
      if (temp.includes(x.label)) {
        x.subItem.change = (val, row, options) => {
          this.calcChange(val, row);
          this.calcMoney(val, row);
        };
      } else if (x.label === '单价') {
        x.subItem.change = (val, row, options) => {
          this.calcMoney(val, row);
        };
      }
    });
  },
  methods: {
    keep2Decimal,
    keep4Decimal,
    initData() {
      this.getContry();
    },
    calcMoney(val, row) {
      let { qtso_price, qutb_qtso_num, qutb_qtso_loss } = row;
      let temp = new BigNumber(qutb_qtso_loss).plus(qutb_qtso_num).times(qtso_price);
      if (!temp.isFinite()) {
        row.qutb_money = 0;
      } else {
        row.qutb_money = temp.toFixed(4);
      }
      if (row.qtso_name === '胶料') {
        let find = this.qutbForm.qutb_qtso_sala_list.filter(x => x.destroy_flag !== 1).find(x => x.qtso_name === '炼胶人工');
        if (find) this.$emit('changeRubberMaterial', find);
      }
    },
    calcChange(val, row) {
      if (row.qtso_name === '胶料') {
        this.qutbForm.qutb_qtso_mtrl_list
          .filter(x => x.destroy_flag !== 1)
          .forEach(item => {
            if (['橡胶夜光粉', '橡胶颗粒', '橡胶香精'].includes(item.qtso_name)) {
              let { qutb_qtso_num, qutb_qtso_loss, add_quantity } = row;
              let temp = new BigNumber(qutb_qtso_num).plus(qutb_qtso_loss).times(item.add_quantity).div(100);
              temp.isFinite() ? (item.qutb_qtso_num = temp.toFixed(2)) : (item.qutb_qtso_num = 0);
            } else if (item.qtso_name === 'TPE香精' || item.qtso_name === 'TPR香精') {
              let { qutb_qtso_num, add_quantity } = row;
              let temp = new BigNumber(qutb_qtso_num).times(item.add_quantity).div(100);
              temp.isFinite() ? (item.qutb_qtso_num = temp.toFixed(2)) : (item.qutb_qtso_num = 0);
            }
            /*else if (item.qtso_name === '橡胶夜光粉') {
              let { qutb_qtso_num, qutb_qtso_loss } = row;
              let temp = new BigNumber(qutb_qtso_num).plus(qutb_qtso_loss).times(0.02).times(0.3);
              temp.isFinite() ? (item.qutb_qtso_num = temp.toFixed(2)) : (item.qutb_qtso_num = 0);
            } else if (item.qtso_name === '橡胶颗粒') {
              let { qutb_qtso_num, qutb_qtso_loss } = row;
              let temp = new BigNumber(qutb_qtso_num).plus(qutb_qtso_loss).times(0.15).times(0.15);
              temp.isFinite() ? (item.qutb_qtso_num = temp.toFixed(2)) : (item.qutb_qtso_num = 0);
            }*/
            let temp1 = new BigNumber(item.qutb_qtso_loss).plus(item.qutb_qtso_num).times(item.qtso_price);
            if (!temp1.isFinite()) {
              item.qutb_money = 0;
            } else {
              item.qutb_money = temp1.toFixed(4);
            }
          });
        return;
      }
      if (row.qtso_name === '尼龙') {
        this.qutbForm.qutb_qtso_mtrl_list
          .filter(x => x.destroy_flag !== 1)
          .forEach(item => {
            if (item.qtso_name === '尼龙香精') {
              let { qutb_qtso_num, add_quantity, qutb_qtso_loss, qtso_price } = row;
              let temp = new BigNumber(qutb_qtso_num).times(item.add_quantity).div(100);
              temp.isFinite() ? (item.qutb_qtso_num = temp.toFixed(2)) : (item.qutb_qtso_num = 0);
              let temp1 = new BigNumber(item.qutb_qtso_loss).plus(item.qutb_qtso_num).times(item.qtso_price);
              if (!temp1.isFinite()) {
                item.qutb_money = 0;
              } else {
                item.qutb_money = temp1.toFixed(4);
              }
            }
          });
      }
    },
    // 获取打样部门
    getContry() {
      get(optnAPI.getOptnByPermId, { perm_id: 10009 })
        .then(res => {
          if (res.data.code === 0) {
            let beloType = res.data.data.form.optn_cntt_list;
            for (let item of beloType) {
              item.param2 = Number(item.param2);
            }
            this.beloType = beloType;
          }
        })
        .catch(() => {});
    },
    //bom清单选择框
    handleSelectionChange(selection) {
      this.selectionsList = [];
      this.selectionsList = selection;
      this.$emit('handleSelectionChangeBom', this.selectionsList);
    }
  }
};
</script>

<style scoped lang="scss">
.vd_total {
  font-size: 24px;
  text-align: right;
  width: 100%;
  padding-right: 30px;
  box-sizing: border-box;
}

.vd_color {
  color: #c70e0e;
}
</style>
