<template>
  <div>
    <table border="1px" width="100%" cellpadding="0" cellspacing="0" class="vd_table">
      <tr>
        <th class="vd_th">名称</th>
        <th class="vd_th">系数</th>
        <th class="vd_th vd_right">金额</th>
      </tr>
      <tr>
        <td class="vd_td_o">管理费</td>
        <td class="vd_td_t vd_t_w">
          <el-form-item label-width="0">
            <el-input
              @change="qutbProfitRate"
              maxlength="6"
              v-model="qutbForm.qutb_mg_rate"
              @input="val => (qutbForm.qutb_mg_rate = keep2Decimal(val))"
              show-word-limit
              placeholder="暂无"
            ></el-input>
          </el-form-item>
        </td>
        <td class="vd_td_t vd_s_w vd_right">
          {{ x }}
        </td>
      </tr>
      <tr>
        <td class="vd_td_o">利润</td>
        <td class="vd_td_t vd_t_w">
          <el-form-item label-width="0">
            <el-input
              @change="qutbProfitRate"
              maxlength="6"
              v-model="qutbForm.qutb_profit_rate"
              @input="val => (qutbForm.qutb_profit_rate = keep2Decimal(val))"
              show-word-limit
              placeholder="暂无"
            ></el-input>
          </el-form-item>
        </td>
        <td class="vd_td_t vd_s_w vd_right">
          {{ y }}
        </td>
      </tr>
      <tr>
        <td class="vd_td_o">税</td>
        <td class="vd_td_t vd_t_w"></td>
        <td class="vd_td_t vd_s_w vd_right">
          {{ z }}
        </td>
      </tr>
      <tr>
        <td class="vd_td_o">合计</td>
        <td class="vd_td_t vd_t_w">
          <el-form-item label-width="0"> </el-form-item>
        </td>
        <td class="vd_td_t vd_s_w vd_right">
          {{ k }}
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import { keep2Decimal } from '@assets/js/regExUtil';
import { objectArrayReduce } from '@assets/js/arrayUtils';
import { BigNumber } from 'bignumber.js';

export default {
  name: 'QutbMould',
  props: {
    qutbForm: {
      type: Object,
      required: true
    },
    isShow: { type: Boolean, default: false }
  },
  computed: {
    qutbOfferTotalPrice() {
      return objectArrayReduce(
        this.qutbForm.qutb_qtso_sala_list?.filter(x => x.destroy_flag !== 1),
        'qutb_money'
      );
    },
    qutbBomTotalPrice() {
      return objectArrayReduce(
        this.qutbForm.qutb_qtso_mtrl_list?.filter(x => x.destroy_flag !== 1),
        'qutb_money'
      );
    },
    qutbAccessTotalPrice() {
      return objectArrayReduce(
        this.qutbForm.qutb_qtso_accmtrl_list?.filter(x => x.destroy_flag !== 1),
        'qutb_money'
      );
    },
    x() {
      let temp = new BigNumber(this.qutbBomTotalPrice).plus(this.qutbAccessTotalPrice).plus(this.qutbOfferTotalPrice).times(this.qutbForm.qutb_mg_rate);
      if (!temp.isFinite()) {
        this.qutbForm.qutb_pridate_money = 0;
        return '自动计算';
      }
      this.qutbForm.qutb_pridate_money = temp.toFixed(4);
      return temp.toFixed(4);
    },
    y() {
      let temp = new BigNumber(this.qutbBomTotalPrice).plus(this.qutbAccessTotalPrice).plus(this.qutbOfferTotalPrice).times(this.qutbForm.qutb_profit_rate);
      if (!temp.isFinite()) {
        this.qutbForm.qutb_profit_money = 0;
        return '自动计算';
      }
      this.qutbForm.qutb_profit_money = temp.toFixed(4);
      return temp.toFixed(4);
    },
    z() {
      let temp = new BigNumber(this.qutbBomTotalPrice)
        .plus(this.qutbAccessTotalPrice)
        .times(0.05)
        .plus(new BigNumber(this.qutbOfferTotalPrice).plus(this.x).plus(this.y).times(0.13).toNumber());
      if (!temp.isFinite()) {
        this.qutbForm.qutb_taxrate_money = 0;
        return '自动计算';
      }
      this.qutbForm.qutb_taxrate_money = temp.toFixed(4);
      return temp.toFixed(4);
    },
    k() {
      let temp = new BigNumber(this.x).plus(this.y).plus(this.z);
      if (!temp.isFinite()) {
        this.qutbForm.qutb_total = new BigNumber(this.qutbOfferTotalPrice).plus(this.qutbBomTotalPrice).plus(this.qutbAccessTotalPrice).toFixed(4);
      } else {
        this.qutbForm.qutb_total = new BigNumber(this.qutbOfferTotalPrice)
          .plus(this.qutbBomTotalPrice)
          .plus(this.qutbAccessTotalPrice)
          .plus(temp.toFixed(4))
          .toFixed(4);
      }
      return temp.toFixed(4);
    }
  },
  data() {
    return {};
  },
  methods: {
    keep2Decimal,
    qutbProfitRate() {
      // this.$emit('qutbProfitRate');
    }
  }
};
</script>

<style scoped lang="scss">
table .vd_td_t {
  // width:40%;
  height: 30px;
  border-bottom: #e9e9e9 1px solid;
  border-right: #e9e9e9 1px solid;
  border-left: none;
  border-top: #e9e9e9 1px solid;
}
table .vd_td_o {
  width: 20%;
  padding-left: 5px;
  color: #606266;
  border-bottom: #e9e9e9 1px solid;
  border-right: #e9e9e9 1px solid;
  border-left: #e9e9e9 1px solid;
  border-top: none;
}
.vd_td_o:nth-of-type(1) {
  border-top: #e9e9e9 1px solid;
}
table .vd_t_w {
  width: 30%;
}
table .vd_s_w {
  width: 50%;
}
table {
  // border: #f3f3f3 1px solid;
  border: none;
  box-sizing: border-box;
}
.vd_th {
  text-align: left;
  height: 30px;
  border-top: #e9e9e9 1px solid;
  background-color: #f5f7fa;
  color: #909399;
  border: none;
}
.vd_right {
  text-align: right;
  padding-right: 10px;
}
</style>
