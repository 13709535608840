<template>
  <div>
    <DynamicUTable
      ref="multiTable"
      :is-show="isShow"
      :tableData="cTableData"
      :columns="qutbAccessTableProperties"
      @selection-change="handleSelectionChange"
      :needPagination="false"
      :needSearch="false"
      :row-click="(row, column, event) => (currentRow = row)"
    >
    </DynamicUTable>
    <div class="vd_total">
      总计:<span class="vd_color">{{ totalPrice }}</span>
    </div>
  </div>
</template>

<script>
import { BigNumber } from 'bignumber.js';
import { objectArrayReduce } from '@assets/js/arrayUtils';
import DynamicUTable from '@/components/table/DynamicUTable.vue';
import { qutbAccessTableProperties } from '@/views/DevelopManagement/QutbManage/qutb';
// 二部报价明细辅助材料
export default {
  name: 'QutbBom',
  components: { DynamicUTable },
  props: {
    qutbForm: {
      type: Object,
      required: true
    },
    isShow: { type: Boolean, default: false }
  },
  data() {
    return {
      smplFlag: 0,
      validatePass: (rule, value, callback) => {
        let that = this;
        if (value) {
          if (that.smplFlag === 2) {
            callback(new Error(''));
          } else if (that.smplFlag === 0) {
            callback(true);
          }
        }
      },
      selectionsList: [],
      smplType: [
        { id: 1, label: '新款' },
        { id: 2, label: '老款' },
        { id: 3, label: '修改款' }
      ],
      selectFlag: false,
      beloType: [],
      currentRow: {},
      qutbAccessTableProperties: qutbAccessTableProperties,
      qtsoTypeList: [
        { value: 1, label: '原材料' },
        { value: 2, label: '香精' },
        { value: 3, label: '夜光粉' },
        { value: 4, label: '颗粒' },
        { value: 5, label: '矿粉' },
        { value: 6, label: '色粉' },
        { value: 7, label: '成品损耗' },
        { value: 8, label: '辅助料材' },
        { value: 9, label: '水电费' },
        { value: 10, label: '运费' },
        { value: 11, label: '纸箱' },
        { value: 12, label: '塑料袋' },
        { value: 13, label: '人工工资' }
      ]
    };
  },
  computed: {
    cTableData() {
      return this.qutbForm.qutb_qtso_accmtrl_list.filter(x => x.destroy_flag !== 1);
    },
    qutb_money() {
      return this.clacQutbMoney(this.currentRow);
    },
    totalPrice() {
      return objectArrayReduce(
        this.qutbForm.qutb_qtso_accmtrl_list?.filter(x => x.destroy_flag !== 1),
        'qutb_money'
      );
    }
  },
  created() {
    this.initData();
  },
  mounted() {
    let temp = ['日产量', '单价', '用量', '损耗'];
    this.qutbAccessTableProperties.forEach(x => {
      if (temp.includes(x.label)) {
        x.subItem.change = (val, row, options) => this.clacQutbMoney(row);
      }
    });
  },
  methods: {
    clacQutbMoney(row) {
      let { qtso_type, qtso_price, qutb_qtso_num, qutb_qtso_loss, qtso_daypr, qutb_daily_output } = row;
      let result = 0;
      switch (qtso_type) {
        //金额计算公式 = （用量+损耗）*单价 四舍五入保留4位小数
        case 7:
          let cpshtemp = new BigNumber(qutb_qtso_num).plus(qutb_qtso_loss).times(qtso_price);
          if (!cpshtemp.isFinite()) {
            row.qutb_money = 0;
            result = 0;
          } else {
            row.qutb_money = cpshtemp.toFixed(4);
            result = cpshtemp.toFixed(4);
          }
          break;
        case 8:
          // 轧带公式：
          // 1.按日计价为否时：金额 = 单价 * （用量 + 损耗)
          // 2.按日计价为是时：金额 = (单价/日产量) * （用量 + 损耗)  四舍五入保留4位小数
          let zdtemp;
          if (qtso_daypr === 0) zdtemp = new BigNumber(qutb_qtso_num).plus(qutb_qtso_loss).times(qtso_price);
          else zdtemp = new BigNumber(new BigNumber(qtso_price).div(qutb_daily_output)).times(new BigNumber(qutb_qtso_num).plus(qutb_qtso_loss));
          if (!zdtemp.isFinite()) {
            row.qutb_money = 0;
            result = 0;
          } else {
            row.qutb_money = zdtemp.toFixed(4);
            result = zdtemp.toFixed(4);
          }
          break;
        case 9:
          // 炼胶电费金额 = (单价/日产量) * （用量 + 损耗)；四舍五入保留4位小数
          let ljdftemp = new BigNumber(new BigNumber(qtso_price).div(qutb_daily_output)).times(new BigNumber(qutb_qtso_num).plus(qutb_qtso_loss));
          if (!ljdftemp.isFinite()) {
            row.qutb_money = 0;
            result = 0;
          } else {
            row.qutb_money = ljdftemp.toFixed(4);
            result = ljdftemp.toFixed(4);
          }
          break;
        case 10:
          // 运费公式：金额 = (主表纸箱长度 * 主表纸箱宽度 * 主表纸箱高度)/1000000.0 * 单价 / 主表装箱数 四舍五入保留4位小数
          let yftemp = new BigNumber(this.qutbForm.qutb_box_l)
            .times(this.qutbForm.qutb_box_w)
            .times(this.qutbForm.qutb_box_h)
            .div(1000000.0)
            .times(qtso_price)
            .div(this.qutbForm.qutb_qpc);
          if (!yftemp.isFinite()) {
            row.qutb_money = 0;
            result = 0;
          } else {
            row.qutb_money = yftemp.toFixed(4);
            result = yftemp.toFixed(4);
          }
          break;
        case 11:
          //公式：(主表纸箱长度/100.0 + 主表纸箱宽度/100.0 + 0.09） * (主表纸箱宽度/100.0 + 主表纸箱高度/100.0 + 0.06) * 单价 /主表 装箱数*（用量+损耗） 四舍五入保留4位小数
          let zxftemp1 = new BigNumber(new BigNumber(this.qutbForm.qutb_box_l).div(100.0)).plus(new BigNumber(this.qutbForm.qutb_box_w).div(100.0)).plus(0.09);
          let zxftemp2 = new BigNumber(new BigNumber(this.qutbForm.qutb_box_w).div(100.0)).plus(new BigNumber(this.qutbForm.qutb_box_h).div(100.0)).plus(0.06);
          let zxftemp = new BigNumber(zxftemp1)
            .times(zxftemp2)
            .times(qtso_price)
            .div(this.qutbForm.qutb_qpc)
            .times(new BigNumber(qutb_qtso_num).plus(qutb_qtso_loss));
          if (!zxftemp.isFinite()) {
            row.qutb_money = 0;
            result = 0;
          } else {
            row.qutb_money = zxftemp.toFixed(4);
            result = zxftemp.toFixed(4);
          }
          break;
        case 12:
          //金额 = 单价 * （用量 + 损耗) / 主表装袋数 四舍五入保留4位小数
          let sldtemp = new BigNumber(qutb_qtso_num).plus(qutb_qtso_loss).times(qtso_price).div(this.qutbForm.qutb_qpb);
          if (!sldtemp.isFinite()) {
            row.qutb_money = 0;
            result = 0;
          } else {
            row.qutb_money = sldtemp.toFixed(4);
            result = sldtemp.toFixed(4);
          }
          break;
      }
      this.$emit('setFinishedProductLoss');
      return result;
    },
    changeFinishedProductLoss() {
      let find = this.qutbForm.qutb_qtso_accmtrl_list.filter(x => x.destroy_flag !== 1).find(x => x.qtso_name === '成品损耗');
      if (find) this.clacQutbMoney(find);
    },
    initData() {},
    //辅料选择框
    handleSelectionChange(selection) {
      this.selectionsList = [];
      this.selectionsList = selection;
      this.$emit('handleSelectionChangeAccess', this.selectionsList);
    }
  }
};
</script>

<style scoped lang="scss">
.vd_total {
  font-size: 24px;
  text-align: right;
  width: 100%;
  padding-right: 30px;
  box-sizing: border-box;
}

.vd_total1 {
  width: 100%;
  display: flex;
  justify-content: right;
}

.vd_col {
  color: #f00;
}

table .vd_td_t {
  // width:40%;
  height: 30px;
  border-bottom: #e9e9e9 1px solid;
  border-right: #e9e9e9 1px solid;
  border-left: none;
  border-top: #e9e9e9 1px solid;
}

table .vd_td_o {
  width: 20%;
  padding-left: 5px;
  color: #606266;
  border-bottom: #e9e9e9 1px solid;
  border-right: #e9e9e9 1px solid;
  border-left: #e9e9e9 1px solid;
  border-top: none;
}

.vd_td_o:nth-of-type(1) {
  border-top: #e9e9e9 1px solid;
}

table .vd_t_w {
  width: 30%;
}

table .vd_s_w {
  width: 50%;
}

table {
  // border: #f3f3f3 1px solid;
  border: none;
  box-sizing: border-box;
  margin-top: 30px;
}

.vd_th {
  text-align: left;
  height: 30px;
  border-top: #e9e9e9 1px solid;
  background-color: #f5f7fa;
  color: #909399;
  border: none;
}

.vd_right {
  text-align: right;
  padding-right: 10px;
}

.vd_color {
  color: #c70e0e;
}
</style>
